import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity, TextInput } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Table, Row, Rows } from "react-native-table-component";
import { Color, FontFamily, FontSize, Border, Padding } from "../GlobalStyles";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { API_URL } from "../Config";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const Dashboard = () => {
  const navigation = useNavigation();

  const route = useRoute();
  const {user} = route.params;

  const [tasks, setTasks] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [inCompleteTaskCount, setInCompleteTaskCount] = useState();
  const [completedTaskCount, setCompletedTaskCount] = useState();
  const [taskSubset, setTaskSubset] = useState(null);



  useEffect (()=>{
    if(tasks.length > 0){
      let inCompleteCount = 0;
      let completedCount = 0;
      tasks.forEach(i=>{
        if(i.status == 'Pending' || i.status == "In-Process") {
            inCompleteCount ++;
        } else if (i.status == "Completed") {
            completedCount++;
        }
      })
      setInCompleteTaskCount(inCompleteCount);
      setCompletedTaskCount(completedCount);
     } else {
      setInCompleteTaskCount(0);
      setCompletedTaskCount(0);
     }    
  })


 
  const handleRowPress = (taskID) => {
    const selectedTask = tasks.find(task => task.uuId === taskID);

    if(selectedTask){
      const taskSubset = {
        uuId: selectedTask.uuId,
        assignee: selectedTask.assignee,
        category: selectedTask.category,
        status: selectedTask.status,
        deadline: selectedTask.deadline,
        taskDetails: selectedTask.taskDetails,
        priority: selectedTask.priority,
        clientName: selectedTask.clientName,
        siteName: selectedTask.siteName,
        siteAddress: selectedTask.siteAddress,
        siteMobile: selectedTask.siteMobile,
        siteEmail: selectedTask.siteEmail,
        subCategory: selectedTask.subCategory,
        startTime: selectedTask.startTime,
        endTime: selectedTask.endTime,
        photos: selectedTask.photos,
        assignedFrom: selectedTask.assignedFrom,
      };
      navigation.navigate('DashboardUpdate',  { task: taskSubset });
    } else {
      console.error('Task not found')
    }
     
  };

  useEffect(() => {
    let endpoint = API_URL + `/tasks/?assignee=${user.username}`;

    if (user.role === 'Admin' || user.role === 'HR') {
      endpoint = API_URL + '/tasks/all';
    }
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setTasks(data))
      .catch((error) => console.error(error));
  }, [user.username, user.role]);

  const fetchTasks = (filterValue) => {
    let endpoint;

    if (filterValue && user.role === 'Admin' || user.role === 'HR') {
      endpoint = `${API_URL}/tasks/?assignee=&filter=${filterValue}`;
    } else if (filterValue){
      endpoint = `${API_URL}/tasks/?assignee=${user.username}&filter=${filterValue}`;
    }
    else if (user.role === 'Admin' || user.role === 'HR') {
      endpoint = `${API_URL}/tasks/all`;
    } else {
      endpoint = `${API_URL}/tasks/?assignee=${user.username}`;
    }
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setTasks(data))
      .catch((error) => console.error(error));
  };

        useFocusEffect(
          useCallback(() => {
            fetchTasks();
          }, [])
        );

  const tableHead = ["Tasks", "Assignee", "Category", "Status", "Deadline"];
  const tableData = tasks.length > 0 ? tasks.map((task, index) => [
    task.uuId,
    index + 1,
    task.assignee,
    task.category,
    task.status,
    task.deadline,
  ]) : [];

  return (
    <View style={styles.dashboardd}>

      <View style={[styles.background]}>
        <LinearGradient
          style={[styles.background02]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)"]}
        />
      </View>

      <Text style={styles.hltitle}>Hello,</Text>
      <Text style={styles.wlctitle}>Welcome to Maruti Group </Text>

      <View style={styles.TaskBar} >


      <Image
          style={styles.right}
          contentFit="cover"
          source={require("../assets/Right.png")}
        />
        <Text style={styles.rightcount}>: {completedTaskCount} / {tasks.length}</Text>


        <Image
          style={styles.cross}
          contentFit="cover"
          source={require("../assets/Cross.png")}
        />


        <Text style={styles.crosscount}>: {inCompleteTaskCount} / {tasks.length}</Text>

        <Text style={styles.cmplttxt}>Complete</Text>

        <Text style={styles.incmplttxt}>Inomplete</Text>

        {/* <Text style={styles.ActiveUsers}>Active Employees : 28/30</Text> */}


      </View>

      {/* <View style={[styles.selectedDecor, styles.backgroundPosition]} />  */}

        <View style={[styles.labels]}>


          <Table borderStyle={{ borderWidth: 1, borderColor: 'transparent' }}>

            <Row data={tableHead} style={[styles.head]} textStyle={styles.headText} />

            {tableData.map((rowData, index) => (
              <TouchableOpacity key={index} onPress={() => handleRowPress(rowData[0])}>
                <Row
                  key={index}
                  data={rowData.slice(1, 6)}
                  style={styles.row}
                  textStyle={styles.text}
                />
              </TouchableOpacity>
            ))}
          </Table>
        </View>

        <TextInput style={styles.filters} placeholder="Filters" onChangeText={(text=>{
          fetchTasks(text)
          setFilterValue(text)
        })} />
        <Image
          style={styles.filtersIcon}
          contentFit="cover"
          source={require("../assets/FilterIcon.png")}
        />
        </View>
   
  );
};

const styles = StyleSheet.create({

  head: {
    height: hp(3),
    marginBottom: hp(2)
  },

  headText: {
    margin: hp(7),
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    fontSize: '1vw'
  },

  text: {
    margin: '5%',
    textAlign: 'center',
    fontSize: '1vw',
    fontWeight:'600'
  },

  row: {
    backgroundColor: '#EFEFEF',
    marginBottom: '1%',
    borderRadius: 20,
    width: wp(70),
    height: '90%',
    opacity: 0.8,
    position: 'relative',

  }, // Set a height for the rows

  ActiveUsers:{
    right:wp(8),
    top:hp(8),
    color: 'white',
    fontSize:'1vw',
    alignSelf:'flex-end',
    position:'absolute'
  },


  right: {
    height: hp(4.5),
    width: hp(4.5),
    bottom: hp(13.5),
    alignSelf: 'flex-start',
    left: wp(3),
    maxHeight: "100%",
    position: "absolute",
  },

  cross: {
    height: hp(18),
    width: hp(18),
    bottom: hp(6.7),
    left: wp(10),
    alignSelf: 'flex-end',
    maxHeight: "100%",
    position: "absolute",
  },

  rightcount: {
    fontSize: 18,
    color: 'white',
    alignSelf: 'flex-start',
    bottom: hp(15),
    left: wp(6),
    position: 'absolute'
  },
  cmplttxt: {
    fontSize: 12,
    color: 'white',
    alignSelf: 'flex-start',
    bottom: hp(13),
    left: wp(6),
    position: 'absolute'
  },

  crosscount: {
    fontSize: 18,
    color: 'white',
    bottom: hp(15),
    alignSelf: 'flex-end',
    left: wp(17),
    alignSelf: 'center',
    position: 'absolute'
  },
  incmplttxt: {
    fontSize: 12,
    color: 'white',
    bottom: hp(13),
    left: wp(17),
    alignSelf: 'flex-end',
    position: 'absolute'
  },

  background02: {
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background: {
    position: "absolute",
    overflow: "hidden",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  selectedDecor: {
    top: 176,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: Color.white,
    width: 4,
    height: 64,
    left: 0,
    position: "relative",
  },

  labels: {
    top: hp(60),
    left: wp(23),
    alignSelf:'center',
    justifyContent: "space-between",
    overflow: "relative",
    flexDirection: "row",
    position: "absolute",
  },

  filtersIcon: {
    top: hp(51),
    width: wp(1),
    height: hp(2),
    right: wp(17),
    position: 'absolute'
  },
  filters: {
    width: wp(12),
    right: wp(7),
    height: hp(4),
    top: hp(50),
    alignSelf: 'flex-end',
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: Color.slategray,
    borderWidth: 2,
    borderRadius: 20,
    textAlign: 'center',
    fontSize: '1.2vw',
    color: 'gray',
    fontWeight: '500',
  },

  hltitle: {
    fontSize: 35,
    color: 'white',
    top: hp(5),
    left: wp(23),
    fontWeight:"800",
    position: 'absolute'
  },

  wlctitle: {
    fontSize: 35,
    color: 'white',
    top: hp(10),
    left: wp(23),
    position: 'absolute'
  },
  TaskBar: {
    top: hp(20),
    width: wp(70),
    height: hp(25),
    left: wp(23),
    borderWidth: 2,
    alignSelf: 'center',
    borderRadius: 30,
    borderColor: '#D6D6D6',
    position: 'absolute'
  },
  dashboardd: {
    flex: 1,
    width: wp(100),
    height: wp(100),
    overflow: "scroll",
    backgroundColor: "#0C1824"
  },
});

export default Dashboard;
