import React, {useState, useEffect} from "react";
import { StyleSheet, View, Text, TextInput, Pressable,TouchableOpacity, Dimensions } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Image } from "react-native";
import { Color, FontSize, FontFamily, Border, Padding } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { API_URL } from "../Config";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const window = Dimensions.get('window');

const SignIn = () => {
  

  const navigation = useNavigation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [message, setMessage] = useState('');
  const resetSignInPage = () => {
    setEmail("");
    setPassword("");
    setMessage("");
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      resetSignInPage();
    });

    return unsubscribe;
  }, [navigation]);

  const handleLogin = () => {
    fetch(API_URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          // Update UI to show success message
          setMessage("Signed In Successfully");
          setTimeout(() => {
            // Redirect to the success screen
            navigation.navigate("MainScreen", { user: data.username });
          }, 2000); // Redirect after a brief delay
        } else {
          // Handle unsuccessful login
          Alert.alert("Login Failed", "Invalid email or password. Please try again.");
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Error:", error);
        // Show an error message to the user
        Alert.alert("Network Error", "An error occurred. Please try again later.");
      });
  }

  const handleSignUp = () =>{
    navigation.navigate("SignUp");
  }

  const handleForgotPassword =() =>{
    navigation.navigate("ForgotPassword");
  }

  return (
    <View style={styles.signIn}>
    <View style={[styles.background, styles.backgroundPosition]}>
      <View style={[styles.background01, styles.backgroundPosition]} />
      <LinearGradient
        style={[styles.background02, styles.background02Bg]}
        locations={[0.2, 1]}
        colors={["#173452", "rgba(23, 52, 82, 0)","#173452"]}
      />
    </View>

    <LinearGradient
      style={[styles.centerContainer, styles.background02Bg]}
      locations={[0, 1, 0]}
      colors={["rgba(217, 217, 217, 0)", "#143759"]}
    />
  <View>
    <View style={[styles.profile2, styles.contentPosition]}>
      <Image
        style={styles.profileIcon}
        contentFit="cover"
        source={require("../assets/profile.png")}
      />
    </View>

    <View style={[styles.content, styles.contentPosition]}>
      <Text style={[styles.letsSignYou, styles.signClr]}>
        Lets Sign You In
      </Text>
      <Text style={styles.welcomeBack}>{`Welcome Back `}</Text>
      <Image
        style={styles.decorLineIcon}
        contentFit="cover"
        source={require("../assets/line.png")}
      />
    </View>

    <View style={[styles.emailbtn, styles.emailbtnFlexBox]}>
     <TextInput
          style={styles.emailphoneUsername} placeholder="email,phone & username"
          placeholderTextColor={Color.white}
          onChangeText={(text) => setEmail(text)}
        ></TextInput>
    </View>

    <View style={[styles.passwordbtn, styles.emailbtnFlexBox]}>
    <TextInput style={styles.emailphoneUsername}
        placeholder="Password"
        placeholderTextColor={Color.white}
        onChangeText={(text)=> setPassword(text)}></TextInput>
    </View>

    <Pressable style={styles.signupBtn} onPress={handleSignUp}>
      <Text style={[styles.signUp, styles.signUpTypo]}>Sign Up</Text>
      <View style={[styles.signupLine, styles.signinbtnBorder]} />
    </Pressable>

    <Pressable style={[styles.forgotPass, styles.emailbtnFlexBox]} onPress={handleForgotPassword}>
      <Text style={styles.signUpTypo}>Forgot Password ?</Text>
    </Pressable>

    <Pressable style={[styles.signinbtn]} onPress={handleLogin}>
      <Text style={[styles.signIn1, styles.signClr]}>Sign in</Text>
    </Pressable>
    </View>
  </View>
  );
};


const styles = StyleSheet.create({
  backgroundPosition: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background02Bg: {
    backgroundColor: 'transparent',
    position: "absolute",
  },
  emailbtnFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    //position: "absolute",
  },
  contentPosition: {
    left: "50%",
    position: "absolute",
  },
  signClr: {
    color: Color.white,
    textAlign: "center",
    
  },
  signUpTypo: {
    fontSize: 18,
    fontFamily: FontFamily.outfitRegular,
    color: Color.white,
    textAlign: "center",
    
  },
  signinbtnBorder: {
    borderStyle: "solid",
    position: "absolute",
  },
  background01: {
    backgroundColor: "#0C1824",
    position: "absolute",
  },
  background02: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background: {
    position: "absolute",
    overflow: "hidden",
  },
  centerContainer: {
    top: (Dimensions.get('window').width < 600 ) ? 30 : '20%',
    right: (Dimensions.get('window').width < 600 ) ? 30 : '28%',
    bottom: (Dimensions.get('window').width < 600 ) ? 30 : '13%',
    left: (Dimensions.get('window').width < 600 ) ? 30 : '28%',
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
  },
  emailphoneUsername: {
    fontWeight: "50",
    fontFamily:FontFamily.outfitRegular,
    color: Color.white,
    textAlign: "center",
    fontSize: 20,
    //width: 600,
  },
  emailbtn: {
    marginTop: -35,
    paddingHorizontal: 225,
    paddingVertical: 17,
    alignItems: "center",
    height: (Dimensions.get('window').width < 600 ) ? 30 : 50,
    borderWidth: 3,
    borderColor: Color.gray,
    borderRadius: 24,
    left: (Dimensions.get('window').width < 600 ) ? '94%' : '30%',
    right: (Dimensions.get('window').width < 600 ) ? '94%' : '0%',
    top: (Dimensions.get('window').width < 600 ) ? '10%' : '70%',
    width: (Dimensions.get('window').width < 600 ) ? '10%' : '40%',
    flexDirection: "row",
    borderStyle: "solid",
    position: "absolute",
    overflow: "hidden",
  },
  passwordbtn: {
    marginBottom: 30,
    paddingHorizontal: 225,
    paddingVertical: 22,
    alignItems: "center",
    height: (Dimensions.get('window').width < 600 ) ? 30 : 50,
    borderWidth: 3,
    borderColor: Color.gray,
    borderRadius: 24,
    left: (Dimensions.get('window').width < 600 ) ? '94%' : '30%',
    right: (Dimensions.get('window').width < 600 ) ? '94%' : '0%',
    bottom: (Dimensions.get('window').width < 600 ) ? '10%' : '70%',
    width: (Dimensions.get('window').width < 600 ) ? '10%' : '40%',
    flexDirection: "row",
    borderStyle: "solid",
    position: "absolute",
    overflow: "hidden",
  },
  letsSignYou: {
    fontSize: 35,
    fontWeight: "700",
    fontFamily: FontFamily.outfitBold,
    width: 300,
    left: '8%',
  },
  welcomeBack: {
    fontFamily: FontFamily.outfitRegular,
    color: Color.white,
    textAlign: "center",
    fontSize: 32,
    fontWeight:'400',
    lineHeight:'normal',
    left: '8%',
  },
  decorLineIcon: {
    width: 240,
    height: 32,
    top: 3,
    left: '8%',  
  },
  content: {
    marginLeft: -160,
    top: 190,
    width: 301,
    height: 116,
    alignItems: "center",
    fontWeight:'700',
    color: '#FFF',
    fontSize:40,
    flexDirection:'column',
    display:'flex',
    flexShrink:0,
    alignSelf:'center'
  },
  forgotPass: {
    width: "14.17%",
    right: "42.71%",
    marginTop:(Dimensions.get('window').width < 600 ) ? 30 : '38.5%',
    left: "43.13%",
    alignItems: "center",
    
  },
  profileIcon: {
    width: 150,
    height: 150,
    alignSelf:'center',
    left: '0%',
  },
  profile2: {
   marginLeft: -80,
   top: 39,
   elevation: 4,
   paddingHorizontal: 0,
   paddingVertical: 0,
   height: 150,
   overflow: "hidden",
   alignSelf:"center",
   alignItems:'center'
  },
  signUp: {
    width: 88,
    height: 34,
    zIndex: 0,
  },
  signupLine: {
    top: 24,
    left: 10,
    borderColor: "#D6D6D6",
    borderTopWidth: 1,
    width: 66,
    height: 1,
    zIndex: 1,
    alignSelf:'center'
  },
  signupBtn: {
    width: "6.11%",
    bottom:hp(30),
    //bottom: 220,
    left: "47.01%",
    justifyContent: "flex-end",
    position: "absolute",
    alignSelf:'center'
  },
  signIn1: {
    fontSize: 18,
    fontWeight: "500",
    fontFamily: FontFamily.outfitMedium,
    textAlign:'center',
  },
  signinbtn: {
    marginTop: "20%",
    width: "18.75%",
    borderRadius: 30,
    backgroundColor: Color.slategray,
    borderColor: "#8E8383",
    borderWidth: 0.6,
    paddingVertical: '2%',
    alignSelf:'center',
    borderStyle: "solid",
    position: "relative",
    overflow: "hidden",
  },
  signIn: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: "hidden",
  },
});



export default SignIn;



