import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Button } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Table, Row, Rows } from "react-native-table-component";
import { FontFamily, Padding, Color, Border, FontSize } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { API_URL } from "../Config";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

const Directory = () => {
  const navigation = useNavigation();
  const [sites, setSites] = useState([]);

  const handleAddSite = () => {
    navigation.navigate('AddSite');
  }

  const handleRowPress = (taskID) => {
    console.log("selectedSiteId===>", taskID)
    const selectedSite = sites.find(task => task.id === taskID);
    console.log("selected site===>", selectedSite);

    if (selectedSite) {
      const SiteSubset = {
        id: selectedSite.id,
        manager: selectedSite.manager,
        work_type: selectedSite.work_type,
        reference_type: selectedSite.reference_type,
        date_of_birth: selectedSite.date_of_birth,
        registration_date: selectedSite.registration_date,
        clientName: selectedSite.clientName,
        siteName: selectedSite.siteName,
        siteAddress: selectedSite.siteAddress,
        siteMobile: selectedSite.siteMobile,
        siteEmail: selectedSite.siteEmail,
        contractNo: selectedSite.contractNo,
        interior_designer: selectedSite.interior_designer,
      };
      navigation.navigate('UpdateSite', { site: SiteSubset });
    } else {
      console.error('Site not found')
    }

  };

  useEffect(() => {

    let endpoint = API_URL + '/client';

    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setSites(data))
      .catch((error) => console.error(error));
  });
  const tableHead = ["Code", "Client", "Site", "Address", "Mobile", "Manager"];
  const tableData = sites.length > 0 ? sites.map((task, index) => [
    task.id,
    task.clientName,
    task.siteName,
    task.siteAddress,
    task.siteMobile,
    task.manager,
  ]) : [];

  const [fileName, setFileName] = useState('users');

  const handleExcelDownload = () => {


    const excelData = sites.map((site, index) => [site.id, site.clientName, site.siteName, site.siteAddress, site.siteMobile, site.manager]);

    const worksheet = XLSX.utils.aoa_to_sheet([["Code", "Client", "Site", "Address", "Mobile", "Manager"], ...excelData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const chosenFileName = prompt('Enter file name (without extension):') || fileName;
    const finalFileName = chosenFileName.trim() + '.xlsx';

    FileSaver.saveAs(blob, finalFileName);
  };


  return (
    <View style={styles.Directory}>
      <View style={[styles.background]}>
        <LinearGradient
          style={[styles.background02Bg]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)", "#173452"]}
        />
      </View>


      <TouchableOpacity style={[styles.addSiteBX]} onPress={handleAddSite}>
        <Button style={styles.addSiteBX} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}>+ Add Site</Text>
      </TouchableOpacity>

      <TouchableOpacity style={[styles.downloadbtn]} onPress={handleExcelDownload}>
        <Button style={styles.downloadbtn} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}> ↓ Download Excel</Text>
      </TouchableOpacity>

      <View style={[styles.labels]}>
        <Table borderStyle={{ borderWidth: 1, borderColor: 'transparent' }}>

          <Row data={tableHead} style={[styles.head]} textStyle={styles.headText} />

          {tableData.map((rowData, index) => (
            <TouchableOpacity key={index} onPress={() => handleRowPress(rowData[0])}>
              <Row
                key={index}
                data={rowData}
                style={styles.row}
                textStyle={styles.text}
              />
            </TouchableOpacity>
          ))}
        </Table>
      </View>

    </View>

  );
};


const styles = StyleSheet.create({
  labels: {
    top: hp(15),
    left: wp(23),
    height: hp(8),
    width: wp(70),
    justifyContent: "space-between",
    overflow: "relative",
    flexDirection: "row",
    position: "absolute",
  },

  downloadbtn: {
    width: wp(12),
    right: wp(5),
    height: hp(4),
    top: hp(10),
    alignSelf: 'flex-end',
    position: 'absolute',
    borderColor: '#D6D6D6',
    borderWidth: 2,
    borderRadius: 20,
    alignItems:'center',
    textAlign: 'center',
    fontSize: '1.2vw',
    backgroundColor: '#D6D6D6',
    fontWeight: '500',
  },


  head: {
    height: 30,
    marginBottom: 5
  },

  headText: {
    margin: hp(7),
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    fontSize: '1vw'
  },

  text: {
    margin: hp(1),
    textAlign: 'center',
    fontSize: '1vw',
    fontWeight: '600'
  },

  row: {
    backgroundColor: '#EFEFEF',
    marginBottom: 15,
    borderRadius: 20,
    width: wp(75),
    height: hp(6),
    opacity: 0.8,
    position: 'relative',

  },


  addSiteBX: {
    backgroundColor: '#D6D6D6',
    top: '7%',
    paddingVertical: 15,
    height: 10,
    borderWidth: 3,
    borderColor: "#D6D6D6",
    left: "24.5%",
    right: "10%",
    width: "10%",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: 'relative',
    overflow: "hidden",
  },


  background02Bg: {
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },

  background: {
    position: "absolute",
    overflow: "hidden",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },

  Directory: {
    flex: 1,
    width: "100%",
    height: 1024,
    overflow: "scroll",
    backgroundColor: "#0C1824",
  },

});


export default Directory;