import * as React from "react";
import { Pressable, View, TextInput, StyleSheet, Button, Text, Image, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Color, Padding, FontSize, Border, FontFamily } from "../GlobalStyles";
import { useState, useRef, useEffect } from "react";


const OTPVerfication = () => {

    const pin1Ref = useRef(null);
    const pin2Ref = useRef(null);
    const pin3Ref = useRef(null);
    const pin4Ref = useRef(null);

    const [pin1, setPin1] = useState("-");
    const [pin2, setPin2] = useState("-");
    const [pin3, setPin3] = useState("-");
    const [pin4, setPin4] = useState("-");

    return (
        <View style={styles.emailotp}>
            <View style={[styles.background, styles.backgroundPosition]}>
                <View style={[styles.background01, styles.backgroundPosition]} />
                <LinearGradient
                    style={[styles.background02, styles.backgroundPosition]}
                    locations={[0.2, 1]}
                    colors={["#173452", "rgba(23, 52, 82, 0)","#173452"]}
                />
            </View>
            <LinearGradient
                style={[styles.centercontainer, styles.otpcirclesPosition]}
                locations={[0, 0, 1]}
                colors={["rgba(217, 217, 217, 0)", "#143759"]}
            />

            <View style={[styles.otpcircles, styles.profile2FlexBox]}>

                <View style={[styles.otp1, styles.optElipse]}>
                    <TextInput
                        ref={pin1Ref}
                        maxLength={1}
                        // keyboardType='number-pad'
                        // value={otpDigits[0]}
                        // onChangeText={(text) => handleOtpChange(0, text)}
                        onKeyPress={({ nativeEvent }) => {
                            setPin1(pin1);
                            if (nativeEvent.key !== "") {
                                pin2Ref.current.focus();
                            }
                            if (nativeEvent.key === 'Backspace') {
                                pin1Ref.current.focus();
                            }
                        }}
                        style={[styles.otp1txt, styles.otp1txtPosition]}
                        placeholder="-" placeholderTextColor={Color.white}></TextInput>
                </View>

                <View style={[styles.otp2, styles.optElipse]}>
                    <TextInput
                        ref={pin2Ref}
                        maxLength={1}
                        // keyboardType='number-pad'
                        // value={otpDigits[1]}
                        // onChangeText={(text) => handleOtpChange(1, text)}
                        onKeyPress={({ nativeEvent }) => {
                            setPin2(pin2);
                            if (nativeEvent.key !== "") {
                                pin3Ref.current.focus();
                            }
                            if (nativeEvent.key === 'Backspace') {
                                pin1Ref.current.focus();
                            }
                        }}
                        style={[styles.otp2txt, styles.otp2txtPosition]}
                        placeholder="-"
                        placeholderTextColor={Color.white}></TextInput>
                </View>

                <View style={[styles.otp3, styles.optElipse]}>
                    <TextInput
                        ref={pin3Ref}
                        maxLength={1}
                        // keyboardType='number-pad'
                        // value={otpDigits[2]}
                        // onChangeText={(text) => handleOtpChange(2, text)}
                        onKeyPress={({ nativeEvent }) => {
                            setPin3(pin3);
                            if (nativeEvent.key !== "") {
                                pin4Ref.current.focus();
                            }
                            if (nativeEvent.key === 'Backspace') {
                                pin2Ref.current.focus();
                            }
                        }}
                        style={[styles.otp3txt, styles.otp3txtPosition]} placeholder="-"
                        placeholderTextColor={Color.white}></TextInput>
                </View>

                <View style={[styles.otp4, styles.optElipse]}>
                    <TextInput
                        ref={pin4Ref}
                        maxLength={1}
                        // keyboardType='number-pad'
                        // value={otpDigits[3]}
                        // onChangeText={(text) => handleOtpChange(3, text)}
                        onKeyPress={({ nativeEvent }) => {
                            setPin4(pin4);
                            if (nativeEvent.key === 'Backspace') {
                                pin3Ref.current.focus();

                            }
                        }}
                        style={[styles.otp4txt, styles.otp4txtPosition]} placeholder="-"
                        placeholderTextColor={Color.white}></TextInput>
                </View>
            </View>

            <View style={[styles.timertxt, styles.timertxtPosition]}>
                <Text style={[styles.text, styles.textTypo]}>{`00.30 `}</Text>
            </View>

            <View style={[styles.didnottxt, styles.opt4txtPosition]}>
                <Text style={[styles.text, styles.textTypo]}>Did not receive OTP?</Text>
            </View>

            <View style={[styles.resendtxt, styles.opt4txtPosition]}>
                <Text style={[styles.resendOtp, styles.textTypo]}>Resend OTP</Text>
            </View>

            <View style={[styles.titletxt, styles.opt4txtPosition]}>
                <Text style={[styles.emailVerification, styles.opt4txtFlexBox]}>
                    Phone Verification
                </Text>
                <Image
                    style={styles.decorLineIcon}
                    contentFit="cover"
                    source={require("../assets/line.png")}
                />
            </View>

            <View style={[styles.contenttxt, styles.timertxtPosition]}>
                <Text style={[styles.weHaveSentContainer, styles.opt4txtFlexBox]}>
                    <Text style={styles.weHaveSent}
                    >{`We have sent you a one time password on your
`}</Text>
                    <Text style={styles.emailAddress}>Phone Number</Text>
                </Text>
            </View>

            <View style={[styles.registerbtn, styles.opt4txtPosition]}>
                <Text style={styles.register}>Register</Text>
            </View>

            <View style={[styles.profile2, styles.opt4txtPosition]}>
                <Image
                    style={styles.profileIcon}
                    contentFit="cover"
                    source={require("../assets/profile.png")}
                />
            </View>
        </View>
    );
};


const styles = StyleSheet.create({
    backgroundPosition: {
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
        position: "absolute",
    },
    otpcirclesPosition: {
        left: 302,
        right: 302,
        position: "absolute",
    },
    profile2FlexBox: {
        justifyContent: "center",
        flexDirection: "row",
    },
    opt4txtFlexBox: {
        textAlign: "center",
        color: Color.white,
    },
    timertxtPosition: {
        padding: 10,
        left: "50%",
        justifyContent: "center",
        flexDirection: "row",
        position: "absolute",
    },
    textTypo: {
        fontSize: FontSize.bodyRegular_size,
        textAlign: "center",
        color: Color.white,
    },
    opt4txtPosition: {
        left: "50%",
        position: "absolute",
    },
    background01: {
        backgroundColor: "#0C1824",
    },
    background02: {
        backgroundColor: "transparent",
    },
    background: {
        overflow: "hidden",
    },
    centercontainer: {
        top: 186,
        bottom: 141,
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        backgroundColor: "transparent",
    },
    otpIcon: {
        width: 50,
        height: 50,
        zIndex: 0,
    },
    opt4txt: {
        marginTop: -15,
        marginLeft: -15,
        top: "50%",
        fontSize: FontSize.size_5xl,
        width: 30,
        zIndex: 1,
        fontFamily: FontFamily.outfitRegular,
        left: "50%",
        position: "absolute",
    },
    otp4: {
        flexDirection: "row",
    },
    otp3: {
        marginLeft: 111,
        flexDirection: "row",
    },
    otpcircles: {
        height: "7%",
        top: "50%",
        bottom: "42.97%",
        alignItems: "center",
        left: 302,
        right: 302,
        position: "absolute",
        overflow: "hidden",
    },
    optElipse: {
        borderRadius: 24,
        backgroundColor: "rgba(217, 217, 217, 0)",
        borderStyle: "solid",
        borderColor: "rgba(255, 255, 255, 0.5)",
        borderWidth: 2,
        width: 45,
        height: 45,
    },
    otp1: {
        flexDirection: "row",

    },
    otp2: {
        marginLeft: 25,
        flexDirection: "row",
    },
    otp3: {
        marginLeft: 25,
        flexDirection: "row",
    },

    otp4: {
        marginLeft: 25,
        flexDirection: "row",
    },

    otp1txt: {

        marginLeft: -15,
        top: "50%",
        fontSize: FontSize.size_5xl,
        color: Color.black,
        width: 30,
        height: 30,
        zIndex: 1,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        left: "50%",
        position: "absolute",
    },
    otp2txt: {

        marginLeft: -15,
        top: "50%",
        fontSize: FontSize.size_5xl,
        color: Color.black,
        width: 30,
        height: 30,
        zIndex: 1,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        left: "50%",
        position: "absolute",
    },

    otp3txt: {
        marginLeft: -15,
        top: "50%",
        fontSize: FontSize.size_5xl,
        color: Color.black,
        width: 30,
        height: 30,
        zIndex: 1,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        left: "50%",
        position: "absolute",
    },

    otp4txt: {
        marginLeft: -15,
        top: "50%",
        fontSize: FontSize.size_5xl,
        color: Color.black,
        width: 30,
        height: 30,
        zIndex: 1,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        left: "50%",
        position: "absolute",
    },

    otp1txtPosition: {
        zIndex: 1,
        color: Color.white,
        fontSize: 25,
        top: 5,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        position: "absolute",
    },
    otp2txtPosition: {
        zIndex: 1,
        color: Color.white,
        fontSize: 25,
        top: 5,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        position: "absolute",
    },

    otp3txtPosition: {
        zIndex: 1,
        color: Color.white,
        fontSize: 25,
        top: 5,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        position: "absolute",
    },

    otp4txtPosition: {
        zIndex: 1,
        color: Color.white,
        fontSize: 25,
        top: 5,
        fontFamily: FontFamily.outfitRegular,
        textAlign: "center",
        position: "absolute",
    },

    text: {
        fontFamily: FontFamily.outfitRegular,
    },
    timertxt: {
        marginLeft: -32,
        top: 510,
        bottom: 340,
        width: 65,
        alignItems: "flex-end",
    },
    didnottxt: {
        marginLeft: -74.5,
        bottom: 250,
        alignItems: "center",
    },
    resendOtp: {
        fontWeight: "600",
        fontFamily: FontFamily.outfitSemiBold,
    },
    resendtxt: {
        marginLeft: -44,
        bottom: 230,
        alignItems: "center",
    },
    emailVerification: {
        fontSize: 25,
        fontWeight: "700",
        fontFamily: FontFamily.outfitBold,
        width: 300,
        top: -70,
    },
    decorLineIcon: {
        width: 240,
        height: 30,
        top: -60,
    },
    titletxt: {
        marginLeft: -149,
        top: 288,
        width: 299,
        alignItems: "center",
    },
    weHaveSent: {
        fontFamily: FontFamily.poppinsRegular,
        textAlign:'center'
    },
    emailAddress: {
        fontFamily: FontFamily.poppinsBold,
        fontWeight: "700",
    },
    weHaveSentContainer: {
        fontSize: 20,
        height: 80,
        flex: 1,
        top: -80,
    },
    contenttxt: {
        marginLeft: -138,
        top: 373,
        width: 276,
        alignItems: "center",
    },
    register: {
        fontWeight: "500",
        fontFamily: FontFamily.outfitMedium,
        color: Color.white,
        textAlign: "center",
        fontSize: 20,
    },
    registerbtn: {
        marginLeft: -171,
        bottom: 106,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        borderRadius: 30,
        width: 342,
        paddingHorizontal: 130,
        paddingVertical: 21,
        alignItems: "flex-end",
    },
    profileIcon: {
        width: 130,
        height: 130,
    },
    profile2: {
        marginLeft: -69,
        top: 59,
        elevation: 4,
        paddingHorizontal: 0,
        paddingVertical: 0,
        height: 150,
        overflow: "hidden",
        alignSelf: "center",
        alignItems: 'center'
    },
    emailotp: {
        width: "100%",
        height: 1024,
        overflow: "hidden",
        flex: 1,
    },
});


export default OTPVerfication;



