import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Text, Image, TextInput, TouchableOpacity, Button, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Padding, Color, FontFamily, FontSize, Border } from "../GlobalStyles";
import { Dropdown } from 'react-native-element-dropdown';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import axios from 'axios';
import { API_URL } from '../Config';
import { Buffer } from 'buffer';
import * as ImagePicker from 'expo-image-picker';


const DashboardUpdate = () => {
  const navigation = useNavigation();
const route = useRoute();
const { task } = route.params;
const [updatedTask, setUpdatedTask] = useState({ ...task });
const [startDate, setStartDate] = useState('');
const [photo,setPhoto] = useState('');
const [endDate, setEndDate] = useState('');
const [profileImage, setProfileImage] = useState('');
const [loading, setLoading] = useState(false);
console.log("updatedtask==>", updatedTask);

const openImageLibrary = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

  if (status !== 'granted') {
    alert('Sorry, we need camera roll permissions to make this work!');
  }

  if (status === 'granted') {
    const response = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
    });
    let uri;
    if (!response.cancelled) {
      if(response){
        response.assets.forEach(i=>{
          if(i.uri){
            uri = i.uri;
          }
        })
      }
      
      if (uri !== profileImage) {
        setProfileImage(uri);
        console.log("profile Image===>", profileImage);
      }
    }
  }
};

const handleTaskStatus = (newStatus) => {
  setUpdatedTask({ ...updatedTask, status: newStatus })
  if(newStatus == 'In-Process'){
    setStartDate(new Date());
  } else if ( newStatus == 'Completed') {
    setEndDate(new Date());
  }
}
useEffect (()=>{
  if(updatedTask.startTime != null || updatedTask.endTime != null){
    setStartDate(updatedTask.startTime)
    setEndDate(updatedTask.endTime)
  } else {
    setStartDate('');
    setEndDate('');   
  }
}, [updatedTask.startTime, updatedTask.endTime])

const requestBody = {
  ...updatedTask,
  startTime: startDate,
  endTime:  endDate 
};

const updateTask = async () => {
  setLoading(true);
  try {
    const response = await axios.put(API_URL + `/tasks/${task.uuId}`, requestBody);

    if (response.status === 200) {
      console.log('Task updated successfully');
      navigation.navigate('Tasks');
      setLoading(false);
    } else {
      console.error('Error updating task');
      setLoading(false);
    }
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

const deleteTask = async () => {
  console.log("task==>", task.uuId);
  try {
    const response = await axios.delete(API_URL + `/tasks/${task.uuId}`);

    if (response.status === 204) {
      console.log('Task deleted successfully');
      navigation.goBack(); 
    } else {
      console.error('Error deleting task');
    }
  } catch (error) {
    console.error(error);
  }
};


const fetchTaskDetails = () => {
  axios.get(API_URL + `/tasks/${task.uuId}`)
    .then(response => setUpdatedTask(response.data))
    .catch(error => console.error(error));
};
useFocusEffect(
  useCallback(() => {
    fetchTaskDetails();
  }, [task.uuId])
);


  const [statusValue, setStatusValue] = useState('null');
  const [isStatusFocus, setIsStatusFocus] = useState(false);
  const statusdata = [
    'Pending', 'In-Process', 'Completed'
  ];
 
   useEffect (()=>{
    if (updatedTask.photos instanceof Object && updatedTask.photos != null) {
      updatedTask.photos = Buffer.from(updatedTask.photos).toString('base64');
      const decodedPhoto = atob(updatedTask.photos);
      setPhoto(decodedPhoto);
     } else if (updatedTask.photos == null){
      setPhoto('')
     }
  }, [updatedTask.photos])
 

  return (
      <View style={styles.dashboard01}>
        <View style={[styles.background, styles.backgroundPosition1]}>
          <View style={[styles.background01, styles.backgroundPosition]} />
          <LinearGradient
            style={[styles.background02, styles.backgroundPosition]}
            locations={[0.2, 1]}
            colors={["#173452", "rgba(23, 52, 82, 0)"]}
          />
        </View>
        <View style={styles.selected} />

        <Text style={[styles.name]}>Name : {updatedTask.assignee}</Text>
        <Text style={[styles.taskstart]}>Start Time : {startDate ? startDate.toLocaleString() : 'Not available'}</Text>
        <Text style={[styles.taskend]}>End Time :  {endDate ? endDate.toLocaleString() : 'Not available'}</Text>
        <TextInput style={styles.detailsbx}
          value={updatedTask.taskDetails}
          onChangeText={text => setUpdatedTask({ ...updatedTask, taskDetails: text })} />

        <Pressable style={styles.documents} onPress={openImageLibrary}>
          
          <View style={[styles.d2, styles.d1FlexBox]}>
          {profileImage ? (  <Image style={styles.upload} contentFit="cover"
               source={{ uri: profileImage }} />) : ( <Image style={styles.doc4} resizeMode="cover" source={{ uri:`${API_URL}/${photo}` }} />)}
          </View>
        </Pressable>

        <View style={[styles.documentsoptn,]}>
          <Text style={[styles.viewDocuments, styles.updateTypo]}>
            Documents
          </Text>
        </View>

        <Dropdown
          style={[styles.statusdropdown, isStatusFocus]}
          placeholderStyle={styles.placeholderStyle}
          selectedTextStyle={styles.selectedTextStyle}
          iconStyle={styles.iconStyle}
          containerStyle={styles.prioritycontainerstyle}
          itemTextStyle={styles.itemprioritycontainerstyle}
          data={statusdata.map((label, index) => ({ label, value: (index + 1).toString() }))}
          maxHeight={300}
          labelField="label"
          valueField="value"
          placeholder={!isStatusFocus ? `${updatedTask.status}` : '...'}
          value={statusValue}
          onFocus={() => setIsStatusFocus(true)}
          onBlur={() => setIsStatusFocus(false)}
          onChange={item => {
            setStatusValue(item.value);
            handleTaskStatus(item.label);
            setIsStatusFocus(false);
          }}
        />

        <View style={[styles.stageoptn]}>
          <Text style={[styles.viewDocuments, styles.updateTypo]}>Status</Text>
        </View>

        <TextInput
          style={[styles.duedatebx]}
          value={updatedTask.deadline}
          onChangeText={text => setUpdatedTask({ ...updatedTask, deadline: text })}
        />
       

        <View style={[styles.duedateoptn]}>
          <Text style={[styles.viewDocuments]}>Due Date</Text>
        </View>

        <View style={[styles.categoryoptn]}>
          <Text style={[styles.viewDocuments, styles.updateTypo]}>Category</Text>
        </View>

        <View style={[styles.subcategoryoptn]}>
          <Text style={[styles.viewDocuments, styles.updateTypo]}>Sub Category</Text>
        </View>
        {/* <Text style={[styles.SubCategorytxt]}>{updatedTask.siteMobile}</Text> */}

        <Text  style={styles.SubCategorytxt}
        >{updatedTask.subCategory}</Text>

        <Text style={styles.categorydropdown} >{updatedTask.category}</Text>

        <Text style={styles.dropdown} >{updatedTask.priority}</Text>
        <View style={[styles.priorityoptn]}>
          <Text style={[styles.viewDocuments]}>Priority</Text>
        </View>
      {loading ? <View style={[styles.updatebtnDisable]}>
        <Text style={[styles.update]}>Update</Text>
      </View> : <TouchableOpacity style={[styles.updatebtn]} onPress={updateTask}>
        <Text style={[styles.update]}>Update</Text>
      </TouchableOpacity>}
        

        <View style={[styles.locationbtn]}>
          <Text style={[styles.update]}>Site Location</Text>
        </View>

        <Text style={[styles.selectAnClient]}>Client Name</Text>
        <Text style={[styles.clientnametxt]}>{updatedTask.clientName}</Text>

        <Text style={[styles.Sitename]}>Site Name</Text>
        <Text style={[styles.Sitenametxt]}>{updatedTask.siteName}</Text>

        <Text style={[styles.Address]}>Address</Text>
        <Text style={[styles.Addresstxt]}>{updatedTask.siteAddress}</Text>

        <Text style={[styles.Contact]}>Contact No.</Text>
        <Text style={[styles.Contacttxt]}>{updatedTask.siteMobile}</Text>

        <Text style={[styles.Assign]}>Assigned From</Text>
        <Text style={[styles.Assigntxt]}>{updatedTask.assignedFrom}</Text>

        <Text style={[styles.Email]}>Email ID</Text>
        <Text style={[styles.Emailtxt]}>{updatedTask.siteEmail}</Text>


        <View style={styles.trash}>
          <TouchableOpacity style={styles.deleteButton} onPress={deleteTask}>
            <Image
              style={styles.trashIcon}
              contentFit="cover"
              source={require("../assets/trash.png")}
            />
          </TouchableOpacity>
        </View>

      </View>
  );
};

const styles = StyleSheet.create({
  backgroundPosition1: {
    right: 0,
    top: 0,
    left: 0,
  },


  Sitename: {
    top: hp(28),
    right: wp(22.5),
    width:wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize:'1vw'
  },
  Sitenametxt: {
    top: hp(31),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  clientnametxt: {
    top: hp(22.5),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  Address: {
    top: hp(68),
    fontWeight: "500",
    fontSize:'1vw',
    left:wp(20),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    position: "absolute",
    color: Color.white,
  },
  Addresstxt: {
    top: hp(70.5),
    left: wp(20),
    width: wp(70),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  Assign: {
    top: hp(44.5),
    right: wp(21),
    width:wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize:'1vw'
  },
  Assigntxt: {
    top: hp(47),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },

  Contact: {
    top: hp(36.5),
    right: wp(22),
    width:wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize:'1vw'
  },
  Contacttxt: {
    top: hp(39.5),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  Email: {
    top: hp(60),
    fontSize: 14,
    // alignSelf: "center",
    left:wp(20),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "500",
    fontSize:'1vw',
    position: "absolute",
    color: Color.white,
  },
  Emailtxt: {
    top: hp(62.5),
    left: wp(20),
    width: wp(70),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },


  selectAnClient: {
    top: hp(20),
    right: wp(22),
    width:wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize:'1vw'
  },

  categorydropdown: {
    top: hp(27),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    backgroundColor: "#D6D6D6",
    textAlign: 'center',
    borderRadius: 5,
    paddingTop: 6,
    fontSize:'1vw',
    fontWeight:'300'
  },

  statusdropdown: {
    top: hp(54.5),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    backgroundColor: "#D6D6D6",
    textAlign: 'center',
    borderRadius: 5,
    paddingTop: 6,
    fontSize:'1vw',
    fontWeight:'300'
  },

  dropdown: {
    top: hp(39),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    backgroundColor: "#D6D6D6",
    textAlign: 'center',
    borderRadius: 5,
    paddingTop: 6,
    fontSize:'1vw',
    fontWeight:'300'
  },
  placeholderStyle: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  selectedTextStyle: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  prioritycontainerstyle: {
    backgroundColor: "#D6D6D6",
    color: 'black',
    borderRadius: 5,
    textAlign: 'center',
  },
  itemprioritycontainerstyle: {
    textAlign: 'center',
  },

  backgroundPosition: {
    bottom: 0,
    position: "absolute",
  },
  nameTypo: {

  },
  d1FlexBox: {
    justifyContent: "space-between",
    width: 40,
    flexDirection: "row",
  },
  stageoptnFlexBox: {
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  updatebtnFlexBox: {
    alignItems: "flex-end",
    paddingVertical: 9,
    width: 109,
    borderRadius: 21,
    bottom: 110,
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  background01: {
    backgroundColor: "#0C1824",
    left: 0,
    right: 0,
    top: 0,
  },
  background02: {
    backgroundColor: "transparent",
    left: 0,
    right: 0,
    top: 0,
  },
  background: {
    bottom: -10,
    left: 0,
    position: "absolute",
    overflow: "hidden",
  },
  selected: {
    height: "8%",
    top: "28.13%",
    bottom: "63.88%",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "white",
    width: 4,
    left: 0,
    position: "absolute",
  },
  name: {
    top: hp(7.5),
    left: wp(20),
    fontSize: '1vw',
    textAlign: "left",
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "600",
    position: "absolute",
    textAlign: "left",
    color: Color.white,
  },
  taskstart: {
    top: hp(7.5),
    left: wp(40),
    fontSize: '1vw',
    textAlign: "left",
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "600",
    position: "absolute",
    textAlign: "left",
    color: Color.white,
  },
  taskend: {
    top: hp(7.5),
    left: wp(60),
    fontSize: '1vw',
    textAlign: "left",
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "600",
    position: "absolute",
    textAlign: "left",
    color: Color.white,
  },
  doc4: {
    backgroundColor: "white",
    width: 40,
    height: 40,
  },
  upload: {
    width: 40,
    height: 40,
  },
  d2: {
    marginLeft: 22,
  },
  documents: {
    top: hp(55),
    right: wp(33),
    height: hp(4),
    flexDirection: "row",
    position: "absolute",
  },
  viewDocuments: {
    color: Color.white,
    fontWeight: "500",
    fontSize:'1vw'
  },
  documentsoptn: {
    top: hp(52),
    fontSize: 14,
    right:wp(34),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "500",
    position: "absolute",
    color: Color.white,
  },

  stageoptn: {
    top: hp(52),
    justifyContent: "center",
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  datetimebx: {
    top: 250,
    width: '80%',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: "#D6D6D6",
    zIndex: 1,
  },
  duedatebx: {
    top: hp(22.5),
    width: wp(30),
    left: wp(20),
    height: hp(4),
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center'
  },
  duedateoptn: {
    top: hp(20),
    left: wp(20),
    fontSize: '1vw',
    textAlign: "left",
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "600",
    position: "absolute",
    textAlign: "left",
    color: Color.white,
  },
  categoryoptn: {
    top: hp(28),
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },

  subcategoryoptn: {
    top: hp(36.5),
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  SubCategorytxt:{
    top: hp(39.5),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    alignSelf: 'center',
    textAlign:'center',
    position: "absolute",
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    color: 'black',
  },
  priorityoptn: {
    top: hp(44.5),
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  update: {
    color: Color.black,
  },
  updatebtn: {
    right: wp(10),
    paddingHorizontal: 32,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingVertical: 9,
    borderRadius: 21,
    bottom: hp(10),
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  updatebtnDisable: {
    opacity:0.5,
    right: wp(10),
    paddingHorizontal: 32,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingVertical: 9,
    borderRadius: 21,
    bottom: hp(10),
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  locationbtn: {
    left: wp(20),
    paddingHorizontal: 32,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 9,
    borderRadius: 21,
    bottom: hp(10),
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  taskDetails: {
    fontSize: '1vw',
  },
  detailsbx: {
    width: wp(70),
    top: hp(10),
    left:wp(20),
    backgroundColor: "#D6D6D6",
    height: hp(8),
    paddingVertical: 4,
    borderRadius: 5,
    justifyContent: "center",
    alignSelf: 'center',
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
    textAlign: 'center',
    color: 'black'
  },

  trashIcon: {
    width: wp(1),
    height: hp(2.5),
  },
  trash: {
    top: hp(3),
    left: wp(25),
    flexDirection: "row",
    position: "absolute",
  },
  dashboard01: {
    backgroundColor: Color.white,
    flex: 1,
    width: wp(100),
    height: hp(100),
    overflow: "hidden",
  },
});

export default DashboardUpdate;