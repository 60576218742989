import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity, Button, TextInput } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Table, Row, Rows } from "react-native-table-component";
import { Color, FontFamily, FontSize, Border, Padding } from "../GlobalStyles";
import { useNavigation, useFocusEffect, useRoute } from "@react-navigation/native";
import { API_URL } from "../Config";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [addUserEnable, setAddUserEnable] = useState(false);
  const navigation = useNavigation();

  const handleAddUserButton = () => {
    if (user.role === 'Admin' || user.role === 'HR') {
      setAddUserEnable(true);
    } else {
      setAddUserEnable(false);
    }

  }

  const route = useRoute();
  const { user } = route.params;

  const handleAddUser = () => {
    navigation.navigate("AddUser")
  };

  const handleRowPress = (userID) => {

    const selectedUser = users.find(user => user.username === userID);

    if (selectedUser) {
      const userSubset = {
        uuId: selectedUser.uuId,
        username: selectedUser.username,
        mobile: selectedUser.mobile,
        email: selectedUser.email,
        role: selectedUser.role,
        address: selectedUser.address,
        date_of_birth: selectedUser.date_of_birth,
        blood_group: selectedUser.blood_group,
        id_proof_number: selectedUser.id_proof_number,
        work_type: selectedUser.work_type,
        previous_work: selectedUser.previous_work,
        previous_work_with: selectedUser.previous_work_with,
        reference_name: selectedUser.reference_name,
        manager: selectedUser.manager,
        date_of_join: selectedUser.date_of_join,
      };
      navigation.navigate('UserUpdate', { users: userSubset });
    } else {
      console.error('User not found')
    }

  };
  useEffect(() => {
    let endpoint = API_URL + `/users/?filter=${user.username}`;

    if (user.role === 'Admin' || user.role === 'HR') {
      endpoint = API_URL + '/users';
    }
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error(error));
    handleAddUserButton();
  }, [user.username, user.role]);

  const fetchTasks = (filterValue) => {
    let endpoint;

    if (filterValue && user.role === 'Admin' || user.role === 'HR') {
      endpoint = `${API_URL}/users/?filter=${filterValue}`;
    } else if (filterValue) {
      endpoint = `${API_URL}/users/?filter=${filterValue}`;
    }
    else if (user.role === 'Admin' || user.role === 'HR') {
      endpoint = `${API_URL}/users`;
    } else {
      endpoint = `${API_URL}/users/?filter=${user.username}`;
    }
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error(error));
  };

  useFocusEffect(
    useCallback(() => {
      fetchTasks();
    }, [])
  );

  // useEffect(() => {
  //     // Fetch tasks from the backend API
  //     fetch(API_URL+'/users')
  //       .then(response => response.json())
  //       .then(data => setUsers(data))
  //       .catch(error => console.error(error));
  //   }, []);

  //   const fetchTasks = (filtervalue) => {
  //     let endpoint = `${API_URL}/users/?filter=${filterValue}`;
  //     fetch(endpoint)
  //       .then(response => response.json())
  //       .then(data => setUsers(data))
  //       .catch(error => console.error(error));
  //   };

  //   useFocusEffect(
  //     useCallback(() => {
  //       fetchTasks();
  //     }, [])
  //   );

  const tableHead = ['Username', 'Role', 'Manager', 'Punch-In', 'Punch-Out'];

  const tableData = users.length > 0 ? users.map((user, index) => [
    user.uuId,
    user.username,
    user.role,
    user.manager,
    user.punch_in,
    user.punch_out,
  ]) : [];

  const [fileName, setFileName] = useState('users');

  const handleExcelDownload = () => {
    
 
    const excelData = users.map((user, index) => [index+1, user.username, user.role, user.manager, user.punch_in, user.punch_out]);
  
    const worksheet = XLSX.utils.aoa_to_sheet([['ID', 'Username', 'Role', 'Manager', 'Punch-In','Punch-Out' ], ...excelData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
  
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const chosenFileName = prompt('Enter file name (without extension):') || fileName;
    const finalFileName = chosenFileName.trim() + '.xlsx';
  
    FileSaver.saveAs(blob, finalFileName);
  };

  const [attendanceFileName, setAttendanceFileName] = useState('Attendance');
  const [attendance, setAttendance] = useState();

  useEffect(()=>{
     fetch(API_URL + '/get-users')
    .then(response => response.json())
    .then(data => setAttendance(data))
    .catch(error => console.error(error));
  })

  const handleExcelDownloadForAttendance = () => {

    const excelData = attendance.map((user, index) => [index+1, user.username, user.punch_in, user.punch_out, user.address, user.total_hour, user.role]);
  
    const worksheet = XLSX.utils.aoa_to_sheet([['ID', 'Username', 'Punch_In', 'Punch_Out', 'Address','Total Hour', 'Role' ], ...excelData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'usersAttendance');
  
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const chosenFileName = prompt('Enter file name (without extension):') || attendanceFileName;
    const finalFileName = chosenFileName.trim() + '.xlsx';
  
    FileSaver.saveAs(blob, attendanceFileName);
  };


  return (
    <View style={styles.dashboardd}>
      <View style={[styles.background]}>
        <View style={[styles.background01]} />
        <LinearGradient
          style={[styles.background02]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)"]}
        />
      </View>

      {/* <View style={[styles.selectedDecor]} /> */}

      <View style={[styles.labels]}>

        <Table borderStyle={{ borderWidth: 1, borderColor: 'transparent' }}>
          <Row data={tableHead} style={[styles.head,]} textStyle={styles.headText} />
          {tableData.map((rowData, index) => (
            <TouchableOpacity key={index} onPress={() => handleRowPress(rowData[1])}>
              <Row
                key={index}
                data={rowData.slice(1, 6)}
                style={styles.row}
                textStyle={styles.text}
              />
            </TouchableOpacity>
          ))}
        </Table>
      </View>

      <TextInput style={styles.filters} placeholder="Filters" value={filterValue} onChangeText={(text => {
        fetchTasks(text)
        setFilterValue(text)
      })} />

      {addUserEnable ? <TouchableOpacity style={[styles.adduserBX]} onPress={handleAddUser}>
        <Button style={styles.adduserBX} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}>+ Add User</Text>
      </TouchableOpacity> : ''}

      <TouchableOpacity style={[styles.downloadbtn]} onPress={handleExcelDownload}>
        <Button style={styles.downloadbtn} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}> ↓ Download Excel</Text>
      </TouchableOpacity> 

      <TouchableOpacity style={[styles.downloadPunchbtn]} onPress={handleExcelDownloadForAttendance}>
        <Button style={styles.downloadPunchbtn} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}>↓ Download Attendance</Text>
      </TouchableOpacity> 

    </View>
  );
};

const styles = StyleSheet.create({
  title: { fontSize: 25, fontWeight: 'bold', marginBottom: '20%', color: 'white', marginLeft: '30%' },
  tableContainer: { borderRadius: 10, overflow: 'hidden' },

  text: {
    margin: '5%',
    textAlign: 'center',
    fontSize: '1vw',
    fontWeight: '600'
  },

  head: {
    height: hp(3),
    marginBottom: hp(2)
  },

  headText: {
    margin: hp(7),
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    // width: wp(6)
  },
  row: {
    backgroundColor: '#EFEFEF',
    marginBottom: '1%',
    borderRadius: 20,
    width: wp(70),
    height: '90%',
    opacity: 0.8,
    position: 'relative',
  },

  downloadbtn: {
    width: wp(12),
    right: wp(2),
    height: hp(4),
    top: hp(11),
    alignSelf: 'flex-end',
    position: 'absolute',
    borderColor: '#D6D6D6',
    borderWidth: 2,
    borderRadius: 20,
    alignItems:'center',
    textAlign: 'center',
    fontSize: '1.2vw',
    backgroundColor: '#D6D6D6',
    fontWeight: '500',
  },

  downloadPunchbtn: {
    width: wp(12),
    right: wp(2),
    height: hp(4),
    top: hp(16),
    alignSelf: 'flex-end',
    position: 'absolute',
    borderColor: '#D6D6D6',
    borderWidth: 2,
    borderRadius: 20,
    alignItems:'center',
    textAlign: 'center',
    fontSize: '1.2vw',
    backgroundColor: '#D6D6D6',
    fontWeight: '500',
  },

  roleTypo: {
    textAlign: "left",
    color: Color.white,
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: 20,
  },
  background01: {
    backgroundColor: "#0C1824",
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    position: "absolute",
  },
  background02: {
    backgroundColor: "transparent",
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    position: "absolute",
  },
  background: {
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    position: "absolute",
    overflow: "hidden",
    left: 0,
    position: "absolute",
  },
  selectedDecor: {
    top: 176,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: Color.white,
    width: 4,
    height: 64,
    left: 0,
    position: "absolute",
  },

  adduserBX: {
    backgroundColor: '#D6D6D6',
    top: '7%',
    paddingVertical: 15,
    height: 10,
    borderWidth: 3,
    borderColor: "#D6D6D6",
    left: "24.5%",
    right: "10%",
    width: "10%",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: 'relative',
    overflow: "hidden",
  },

  adduserclr: {
    backgroundColor: 'black'
  },

  labels: {
    top: hp(20),
    left: wp(23),
    fontSize: '1vw',
    fontWeight: '600',
    alignSelf: 'center',
    justifyContent: "space-between",
    overflow: "relative",
    flexDirection: "row",
    position: "absolute",
  },
  filtersIcon: {
    width: 206,
    height: 36,
  },
  filters: {
    width: wp(12),
    right: wp(2),
    height: hp(4),
    top: hp(6),
    alignSelf: 'flex-end',
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: Color.slategray,
    borderWidth: 2,
    borderRadius: 20,
    textAlign: 'center',
    fontSize: '1.2vw',
    color: 'gray',
    fontWeight: '500',
  },
  dashboardd: {
    flex: 1,
    width: "100%",
    height: '100%',
    overflow: "scroll",
    backgroundColor: "#0C1824"
  },
});

export default UserManagement;