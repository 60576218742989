import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Pressable, Image, TextInput, TouchableOpacity, FlatList } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, Padding, Color, Border, FontSize } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { API_URL } from "../Config";
import { Dimensions } from "react-native";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const SignIn001 = () => {
    const navigation = useNavigation();

    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState();
    const [message, setMessage] = useState('');
    const resetSignInPage = () => {
        setEmail("");
        setPassword("");
        setMessage("");
    };

    useEffect(() => {
        const unsubscribe = navigation.addListener("focus", () => {
            resetSignInPage();
        });

        return unsubscribe;
    }, [navigation]);

    const handleLogin = () => {
        setLoading(true);
        fetch(API_URL + "/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    // Update UI to show success message
                    setMessage("Signed In Successfully");
                    setTimeout(() => {
                        // Redirect to the success screen
                        navigation.navigate("MainScreen", { user: data.username });
                        setLoading(false);
                    }, 2000); // Redirect after a brief delay
                } else {
                    // Handle unsuccessful login
                    window.alert("Login Failed", "Invalid email or password. Please try again.")
                    setLoading(false);
                }
            })
            .catch((error) => {
                // Handle network errors
                console.error("Error:", error);
                // Show an error message to the user
                window.alert("You entered invalid username or password.", "An error occurred. Please try again later.")
                setLoading(false);
            });
    }

    const handleSignUp = () => {
        navigation.navigate("SignUp");
    }

    const handleForgotPassword = () => {
        navigation.navigate("ForgotPassword");
    }

    return (
        <View style={styles.register}>
            <View style={[styles.background]}>
                <LinearGradient
                    style={[styles.background02Bg]}
                    locations={[0.2, 1]}
                    colors={["#173452", "rgba(23, 52, 82, 0)", "#173452"]}
                />
            </View>

            <LinearGradient
                style={[styles.centercontainer]}
                locations={[0, 0, 1]}
                colors={["rgba(217, 217, 217, 0)", "#143759"]}
            >

                <View style={[styles.uploadbtnIcon]}>
                    <Image
                        style={styles.uploadbtnIcon}
                        contentFit="cover"
                        source={require("../assets/profile.png")}
                    />
                </View>

                {/* <View style={[styles.content]}> */}
                <Text style={[styles.registerAccount]}>
                    Let's Sign you in
                </Text>
                <Text style={[styles.registerAccount]}>
                    Welcome Back
                </Text>
                <Image
                    style={styles.decorLineIcon}
                    contentFit="cover"
                    source={require("../assets/line.png")}
                />
                {/* </View> */}


                <View style={[styles.namebx]}>
                    <TextInput style={[styles.name]}
                        placeholder="Name"
                        placeholderTextColor={Color.white}
                        value={email}
                        onChangeText={text => setEmail(text)}></TextInput>
                </View>

                <View style={[styles.passwordlbx]}>
                    <TextInput style={[styles.password]}
                        placeholder="Password"
                        placeholderTextColor={Color.white}
                        secureTextEntry
                        value={password}
                        onChangeText={text => setPassword(text)}></TextInput>
                </View>

                <View style={styles.popupbx}>
                    <Text style={[styles.popup]}>{message}</Text>
                </View>
                {loading ? <View style={styles.registerbtnDissable} >
                    <Text style={styles.registertxt}>Sign In</Text>
                </View> : <TouchableOpacity style={styles.registerbtn} onPress={handleLogin}>
                    <Text style={styles.registertxt}>Sign In</Text>
                </TouchableOpacity>}


            </LinearGradient>

        </View>

    );
};


const styles = StyleSheet.create({

    registertxt: {
        fontSize: '1.2vw',
        fontWeight: "500",
        fontFamily: FontFamily.outfitMedium,
        color: "white",
        textAlign: "center",
        bottom: 10
    },

    registerbtn: {
        width: "20%",
        height: 20,
        bottom: '-22%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },
    registerbtnDissable: {
        width: "20%",
        opacity: 0.5,
        height: 20,
        bottom: '-22%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },


    popupbx: {
        width: "100%",
        bottom: hp(20),
        alignSelf:'center',
        height: 16,
        position: "absolute",
    },

    popup: {
        fontSize: '1.2vw',
        fontWeight: "500",
        fontFamily: FontFamily.outfitMedium,
        textAlign: "center",
        color: Color.white,
    },
    decorLineIcon: {
        width: 260,
        height: 33,
        top: '-15%',
        position: 'relative',
        alignSelf: 'center',
        flexDirection: 'column'
    },

    registerAccount: {
        top: '-15%',
        width: '80%',
        fontSize: '2vw',
        left: '10%',
        fontWeight: "700",
        fontFamily: FontFamily.outfitMedium,
        textAlign: "center",
        color: Color.white,
        position: 'relative',
        flexDirection: 'column'
    },

    uploadbtnIcon: {
        width: 150,
        height: 150,
        top: '-16%',
        alignSelf: 'center',
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'column'

    },

    passwordlbx: {
        top: '05%',
        bottom: '80%',
        // paddingHorizontal: 260,
        // paddingVertical: 20,
        height: '10%',
        borderWidth: 3,
        borderColor: "white",
        left: "10%",
        right: "10%",
        width: "80%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    password: {
        fontSize: '1.5vw',
        fontWeight: "500",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        width: '100%'
    },

    namebx: {
        top: '0%',
        height: "10%",
        borderWidth: 3,
        borderColor: "white",
        left: "10%",
        right: "10%",
        width: "80%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    name: {
        fontSize: '1.5vw',
        fontWeight: "500",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        width: '100%'
    },
    centercontainer: {
        top: '20%',
        left: '10%',
        right: '10%',
        height: '70%',
        width: '80%',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        backgroundColor: "transparent",
        position: "relative",
    },

    background02Bg: {
        backgroundColor: "transparent",
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    background: {
        position: "absolute",
        overflow: "hidden",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    register: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: "scroll",
        backgroundColor: "#0C1824",
    },

});


export default SignIn001;
