/* fonts */
import * as Font from 'expo-font';

 export const FontFamily = Font.loadAsync({
  outfitRegular: "Outfit-Regular",
  outfitBold: "Outfit-Bold",
  outfitMedium: "Outfit-Medium",
  outfitThin: "Outfit-Thin",
  poppinsSemiBold: "Poppins-SemiBold",
  poppinsBold: "Poppins-Bold",
  poppinsLight: "Poppins-Light",
  interRegular: "Inter-Regular",
 });
  /* font sizes */
  export const FontSize = {
    size_xs: 12,
    size_5xl: 24,
    size_sm: 14,
    size_base: 16,
  };
  /* Colors */
  export const Color = {
    white: "#fff",
    slategray: "#455d75",
    gray: "rgba(255, 255, 255, 0.5)",
  };
  /* border radiuses */
  export const Border = {
    br_11xl: 30,
  };