import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Pressable, Image, TextInput, TouchableOpacity, FlatList } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, Padding, Color, Border, FontSize } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { API_URL } from "../Config";
import { Dropdown } from 'react-native-element-dropdown';


const SignUp = () => {

  const navigation = useNavigation();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');


  const handleOtpVerification = async () => {
    const response = await fetch(API_URL + '/registerUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, mobile, username, role, password, confirmPassword }),
    });

    const data = await response.json();
    console.log("data===>", data);

    if (data.success) {
      // setMessage('OTP verification successful. Registering...');
      setTimeout(() => {
        // setMessage('Registration successful!');
        setTimeout(() => {
          navigation.navigate('SignIn');
        }, 2000); // Redirect to the success screen after a brief delay
      }, 2000); // Simulate registration process with a brief delay
    } else {
      // setMessage('OTP verification failed. Please try again.');
    }
  };


  const handleLogin = () => {
    navigation.navigate('SignIn');
  }


  const handleRegister = async () => {
    setError('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (username == "" || email == "" || password == "") {
      setError('field can not blank')
      return;
    }

    const registrationData = { username, email, mobile, password, confirmPassword, selectedOption };
  };


  const [roleValue, setRoleValue] = useState(null);
  const [isRoleFocus, setIsRoleFocus] = useState(false);
  const roledata = [
    'Admin', 'HR', 'Manager','Employee'
  ];



  return (
    <View style={styles.register}>
      <View style={[styles.background, styles.backgroundPosition]}>
        <View style={[styles.background01, styles.backgroundPosition]} />
        <LinearGradient
          style={[styles.background02, styles.background02Bg]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)", "#173452"]}
        />
      </View>

      <LinearGradient
        style={[styles.centercontainer, styles.background02Bg]}
        locations={[0, 0, 1]}
        colors={["rgba(217, 217, 217, 0)", "#143759"]}
      />

      {/* <View style={[styles.content, styles.contentPosition]}> */}
      <Text style={[styles.registerAccount]}>
        Register User
      </Text>
      <Image
        style={styles.decorLineIcon}
        contentFit="cover"
        source={require("../assets/line.png")}
      />
      {/* </View> */}

      <TouchableOpacity style={styles.registerbtn} onPress={handleOtpVerification}>
        <Text style={styles.register1}>Register</Text>
      </TouchableOpacity>

      <View style={[styles.namebx]}>
        <TextInput style={[styles.name]}
          placeholder="Name"
          placeholderTextColor={Color.white}
          onChangeText={text => setUsername(text)}></TextInput>
      </View>

      <View style={[styles.rolebx]}>
        {/* <TextInput style={[styles.role]}
          placeholder="Role"
          placeholderTextColor={Color.white}
          onChangeText={text => setRole(text)}></TextInput> */}

<Dropdown
          style={[styles.role, isRoleFocus]}
          placeholderStyle={styles.placeholderStyle}
          selectedTextStyle={styles.selectedTextStyle}
          iconStyle={styles.iconStyle}
          containerStyle={styles.prioritycontainerstyle}
          itemTextStyle={styles.itemprioritycontainerstyle}
          data={roledata.map((label, index) => ({ label, value: (index + 1).toString() }))}
          maxHeight={300}
          labelField="label"
          valueField="value"
          placeholder={!isRoleFocus ? 'Role' : '...'}
          value={roleValue}
          onFocus={() => setIsRoleFocus(true)}
          onBlur={() => setIsRoleFocus(false)}
          onChange={item => {
            setRoleValue(item.value);
            setRole(item.label);
            setIsRoleFocus(false);
          }}
        />
      </View>

      <View style={[styles.phonebx, styles.namebxLayout]}>
        <TextInput style={[styles.name, styles.nameTypo]}
          placeholder="Phone"
          placeholderTextColor={Color.white}
          onChangeText={text => setMobile(text)}></TextInput>
      </View>

      <View style={[styles.emailbx, styles.namebxLayout]}>
        <TextInput style={[styles.name, styles.nameTypo]}
          placeholder="Email"
          placeholderTextColor={Color.white}
          onChangeText={text => setEmail(text)}></TextInput>
      </View>

      <View style={[styles.passwordbx, styles.namebxLayout]}>
        <TextInput style={[styles.name, styles.nameTypo]}
          placeholder="Password"
          placeholderTextColor={Color.white}
          onChangeText={text => setPassword(text)}></TextInput>
      </View>

      <View style={[styles.confirmbx, styles.namebxLayout]}>
        <TextInput style={[styles.name, styles.nameTypo]}
          placeholder="Confirm Password"
          placeholderTextColor={Color.white}
          onChangeText={text => setConfirmPassword(text)}></TextInput>
      </View>

      <View style={styles.loginbtn}>
        <Text style={styles.login}>
          <Text style={styles.alreadyHaveAnAccountLogi}>
            <Text
              style={styles.alreadyHaveAn}
            >{`Already have an account ? `}</Text>
            <Text style={styles.login1Typo} onPress={handleLogin}>Login</Text>
          </Text>
          <Text style={styles.text}>{`  `}</Text>
        </Text>
      </View>

      <View style={[styles.uploadbtn]}>
        <Image
          style={styles.uploadbtnIcon}
          contentFit="cover"
          source={require("../assets/Upload.png")}
        />
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  backgroundPosition: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background02Bg: {
    backgroundColor: "transparent",
    position: "absolute",
  },
  uploadbtnFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
  },

  dropdownSelector: {
    borderWidth: 3,
    borderColor: "rgba(255, 255, 255, 0.5)",
    height: 50,
    width: "56.25%",
    top: "71.2%",
    bottom: "32.84%",
    borderRadius: 24,
    paddingHorizontal: 20,
    paddingVertical: 10,
    alignItems: "center",
    left: "22.01%",
    right: "21.74%",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
  },
  otpoptionstxt:
  {
    height: 15,
    display: "flex",
    textAlign: "center",
    fontFamily: FontFamily.outfitThin,
    fontWeight: "400",
    fontSize: 13,
    alignItems: "center",
    color: Color.white,
    justifyContent: "center",
  },
  dropdownArea: {
    borderWidth: 2,
    borderColor: "rgba(255, 255, 255, 0.5)",
    height: 83,
    borderRadius: 24,
    marginTop: 720,
    elevation: 5,
    alignSelf: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    left: "22.01%",
    right: "21.74%",
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
    backgroundColor: Color.slategray,
  },
  otpItem: {
    width: '80%',
    height: 40,
    borderBottomWidth: .4,
    borderBottomColor: '#8e8e8e',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  otpItemtxt: {
    height: 20,
    display: "flex",
    textAlign: "center",
    fontFamily: FontFamily.outfitThin,
    fontWeight: "200",
    fontSize: 18,
    alignItems: "center",
    color: Color.white,
    justifyContent: "center",
  },


  dropdown: {
    height: 31,
    width: 160,
    backgroundColor: "#D6D6D6",
    borderRadius: 30,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
  selectedTextStyle: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  prioritycontainerstyle: {
    backgroundColor: "#D6D6D6",
    color: 'white',
    borderRadius: 30,
    textAlign: 'center',
    borderWidth:3,
    opacity:0.5
  },
  itemprioritycontainerstyle: {
    textAlign: 'center',
    color:'black',
  },

  nameTypo: {
    fontFamily: FontFamily.outfitThin,
    fontWeight: "100",
  },

  login1Typo: {
    fontFamily: FontFamily.outfitBold,
    fontWeight: "700",
  },
  namebxLayout: {
    paddingVertical: 20,
    height: 50,
    borderWidth: 3,
    borderColor: "white",
    opacity: "50%",
    left: "22.01%",
    right: "21.74%",
    top: "58%",
    width: "56.25%",
    justifyContent: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: "absolute",
    overflow: "hidden",
  },
  background01: {
    backgroundColor: "#0C1824",
    position: "absolute",
  },
  background02: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background: {
    position: "absolute",
    overflow: "hidden",
  },
  centercontainer: {
    top: 186,
    right: 302,
    bottom: 60,
    left: 302,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
  },
  selectAnOption: {
    fontSize: 18,
    color: "black",
    width: 146,
    height: 18,
    textAlign: "center",
  },
  downarrowIcon: {
    width: 12,
    height: 7,
    marginLeft: 603,
  },
  selectoptionframe: {
    marginTop: 132.5,
    borderRadius: 24,
    paddingHorizontal: 18,
    paddingVertical: 19,
    alignItems: "center",
    left: "22.01%",
    right: "21.74%",
    top: "57%",
    width: "56.25%",
    flexDirection: "row",
    position: "absolute",
    backgroundColor: Color.white,
  },
  registerAccount: {
    fontSize: 30,
    fontWeight: "500",
    fontFamily: FontFamily.outfitMedium,
    textAlign: "center",
    color: Color.white,
    top: 120
  },
  decorLineIcon: {
    width: 200,
    height: 25,
    top: 120,
    alignSelf: 'center'
  },
  content: {
    marginLeft: -150,
    top: 210,
    alignItems: "center",
    width: 299,
    left: "50%",
    position: "absolute",
  },
  register1: {
    fontSize: 18,
    fontWeight: "500",
    fontFamily: FontFamily.outfitMedium,
    textAlign: "center",
    color: Color.white,
  },
  registerbtn: {
    width: "18.75%",
    // right: "46.67%",
    bottom: 29,
    // left: "35.5%",
    borderRadius: 30,
    backgroundColor: Color.slategray,
    borderColor: "#8E8383",
    borderWidth: 0.6,
    paddingHorizontal: 20,
    paddingVertical: 21,
    alignSelf: 'center',
    borderStyle: "solid",
    position: "absolute",
    overflow: "hidden",
  },
  name: {
    fontSize: 20,
    fontFamily: FontFamily.outfitThin,
    color: "white",
    textAlign: "center",
    fontFamily: FontFamily.outfitThin,
    fontWeight: "100",
    width:'90%'
  },

  namebx: {
    marginTop: -280,
    paddingVertical: 20,
    height: 50,
    borderWidth: 3,
    borderColor: "white",
    opacity: "50%",
    left: 320,
    top: "59%",
    width: 500,
    justifyContent: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
  rolebx: {
    paddingVertical: 20,
    height: 50,
    borderWidth: 3,
    borderColor: "white",
    opacity: "50%",
    left: "58%",
    top: "52.3%",
    width: "20%",
    justifyContent: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
  role: {
    fontSize: 20,
    fontFamily: FontFamily.outfitThin,
    color: "white",
    textAlign: "center",
    fontFamily: FontFamily.outfitThin,
    fontWeight: "100",
    width:'90%'
  },
  phonebx: {
    // marginTop: -141,
    // paddingHorizontal: 358,
    marginTop: -73,
    paddingHorizontal: 363,
  },
  emailbx: {
    // marginTop: -73,
    // paddingHorizontal: 363,
    marginTop: -5,
  },
  passwordbx: {
    // marginTop: -5,
    marginTop: 60,
    paddingHorizontal: 266,
  },
  confirmbx: {
    marginTop: 125,
    paddingHorizontal: 266,
  },
  alreadyHaveAn: {
    fontFamily: FontFamily.outfitRegular,
  },
  alreadyHaveAnAccountLogi: {
    color: Color.white,
  },
  text: {
    color: Color.black,
    fontFamily: FontFamily.outfitRegular,
  },
  login: {
    fontSize: 24,
    width: 371,
    height: 28,
    textAlign: "left",
  },
  loginbtn: {
    bottom: 110,
    left: 326,
    justifyContent: "flex-end",
    position: "absolute",
  },
  uploadbtnIcon: {
    width: 100,
    height: 100,
  },
  uploadbtn: {
    // marginLeft: -85,
    top: 30,
    // padding: 10,
    flexDirection: "row",
    // left: "50%",
    alignSelf: 'center',
    position: "absolute",
  },
  register: {
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "scroll",
    backgroundColor: Color.white,
    position:'relative'
  },
});


export default SignUp;





