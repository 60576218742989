import * as React from "react";
import { StyleSheet, View, Text, Image, Pressable ,Dimensions} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, FontSize, Color, Border } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { TextInput } from "react-native-gesture-handler";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const window= Dimensions.get('window');
const CustomSideMenu002 = () => {

  return (
    <View style={styles.container}>  
        <View style={{flexDirection:'row'}}>
            <Image style={styles.DashboardIcon} source={require("../assets/dashboard.png")}/> <Text style={styles.text1}>Dashboard</Text><br />
        </View>
        <View>
            <Image source={require("../assets/calendar.png")}/> <Text style={styles.text2}>Task</Text><br />
        </View>
        <View>
            <Image source={require("../assets/calendar.png")}/> <Text style={styles.text3}>Calendar</Text><br />
        </View>
        <View>
            <Image source={require("../assets/calendar.png")}/> <Text style={styles.text4}>Performance</Text><br /> 
        </View>
        <View>
        <Image source={require("../assets/calendar.png")}/> <Text style={styles.text5}>Directory</Text><br />
        </View>
        <View>
            <Image source={require("../assets/calendar.png")}/> <Text style={styles.text6}>Settings</Text><br />
        </View>
        <View>
            <Image source={require("../assets/calendar.png")}/> <Text style={styles.text7}>Logout</Text><br />
        </View>




    </View>
  );
};

const styles = StyleSheet.create({
    container:{
        backgroundColor:'gray',
        height:'95%',
        width:"19%",
        borderBottomRightRadius: 30,
        flex:1,
        left:"110%"

    },
    DashboardIcon:{
        marginTop: (Dimensions.get('window').width <600) ? "1%" : "4%",
        height:(Dimensions.get('window').height<600)? "9%":"18%",
        width:(Dimensions.get('window').width<600)? "9%":"2%",
    },
    text1:{
        marginTop: (Dimensions.get('window').width <600) ? "1%" : "4%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    text2:{
        marginTop: (Dimensions.get('window').width <600) ? "0.5%" : "1%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    text3:{
        marginTop: (Dimensions.get('window').width <600) ? "0.5%" : "1%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    text4:{
        marginTop: (Dimensions.get('window').width <600) ? "0.5%" : "1%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    text5:{
        marginTop: (Dimensions.get('window').width <600) ? "0.5%" : "1%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    text6:{
        marginTop: (Dimensions.get('window').width <600) ? "0.5%" : "15%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    text7:{
        marginTop: (Dimensions.get('window').width <600) ? "0.5%" : "1%",
        fontSize: (Dimensions.get('window').width <600) ? 20 : 30,
    },
    
});

export default CustomSideMenu002;