import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Text, Image, Pressable, TouchableOpacity, Button, TextInput } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Table, Row, Rows } from "react-native-table-component";
import { Color, Padding, Border, FontFamily, FontSize } from "../GlobalStyles";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { API_URL } from "../Config";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';


const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const navigation = useNavigation();
  const handleAddTask = () => {
    navigation.navigate('AddTask');

  };
  const route = useRoute();
  const { user } = route.params;

  const handleRowPress = (taskID) => {
    const selectedTask = tasks.find(task => task.uuId === taskID);

    if (selectedTask) {
      const taskSubset = {
        uuId: selectedTask.uuId,
        assignee: selectedTask.assignee,
        category: selectedTask.category,
        status: selectedTask.status,
        deadline: selectedTask.deadline,
        taskDetails: selectedTask.taskDetails,
        priority: selectedTask.priority,
        clientName: selectedTask.clientName,
        siteName: selectedTask.siteName,
        siteAddress: selectedTask.siteAddress,
        siteMobile: selectedTask.siteMobile,
        siteEmail: selectedTask.siteEmail,
        subCategory: selectedTask.subCategory,
        photo: selectedTask.photo,
        assignedFrom: selectedTask.assignedFrom,
      };
      navigation.navigate('DashboardUpdate', { task: taskSubset });
    } else {
      console.error('Task not found')
    }

  };


  // useEffect(() => {
  //   // let endpoint = API_URL + `/tasks/?assignee=${user.username}`;


  //   let  endpoint = API_URL + '/tasks/all';

  //   fetch(endpoint)
  //     .then((response) => response.json())
  //     .then((data) => setTasks(data))
  //     .catch((error) => console.error(error));
  // });
  const handleIncompleteTask = () => {
    fetchTasks('In-Process,Pending')
  }

  useEffect(() => {
    let endpoint = API_URL + `/tasks/?assignee=${user.username}`;

    if (user.role === 'Admin' || user.role === 'HR') {
      endpoint = API_URL + '/tasks/all';
    }
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setTasks(data))
      .catch((error) => console.error(error));
  }, [user.username, user.role]);

  const fetchTasks = (filterValue) => {
    let endpoint;

    if (filterValue && user.role === 'Admin' || user.role === 'HR') {
      endpoint = `${API_URL}/tasks/?assignee=&filter=${filterValue}`;
    } else if (filterValue) {
      endpoint = `${API_URL}/tasks/?assignee=${user.username}&filter=${filterValue}`;
    }
    else if (user.role === 'Admin' || user.role === 'HR') {
      endpoint = `${API_URL}/tasks/all`;
    } else {
      endpoint = `${API_URL}/tasks/?assignee=${user.username}`;
    }
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => setTasks(data))
      .catch((error) => console.error(error));
  };

  useFocusEffect(
    useCallback(() => {
      fetchTasks();
    }, [])
  );

  const tableHead = ["Tasks", "Assignee", "Category", "Status", "Deadline"];
  const tableData = tasks.length > 0 ? tasks.map((task, index) => [
    task.uuId,
    index + 1,
    task.assignee,
    task.category,
    task.status,
    task.deadline,
  ]) : [];

  const [fileName, setFileName] = useState('tasks');

  const handleExcelDownload = () => {


    const excelData = tasks.map((task, index) => [index + 1, task.assignee, task.category, task.status, task.deadline]);

    const worksheet = XLSX.utils.aoa_to_sheet([['ID', 'assignee', 'category', 'status', 'Deadline'], ...excelData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const chosenFileName = prompt('Enter file name (without extension):') || fileName;
    const finalFileName = chosenFileName.trim() + '.xlsx';

    FileSaver.saveAs(blob, finalFileName);
  };

  return (
    <View style={styles.tasks}>

      <View style={[styles.background]}>
        <LinearGradient
          style={[styles.background02]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)"]}
        />
      </View>


      <Text style={[styles.recentlyAssigned]}>
        Recently Assigned
      </Text>
      <View style={[styles.labels]}>
        <Table borderStyle={{ borderWidth: 1, borderColor: 'transparent' }}>

          <Row data={tableHead} style={[styles.head]} textStyle={styles.headText} />

          {tableData.map((rowData, index) => (
            <TouchableOpacity key={index} onPress={() => handleRowPress(rowData[0])}>
              <Row
                key={index}
                data={rowData.slice(1, 6)}
                style={styles.row}
                textStyle={styles.text}
              />
            </TouchableOpacity>
          ))}
        </Table>
      </View>
      {/* <View style={[styles.user1bx1]} /> */}


      {/* <View style={styles.filters}>
        <Image
          style={styles.filtersIcon}
          contentFit="cover"
          source={require("../assets/filters.png")}
        />
      </View> */}


      <TextInput style={styles.filters} placeholder="Filters" value={filterValue} onChangeText={(text => {
        fetchTasks(text)
        setFilterValue(text)
      })} />
      <Image
        style={styles.filtersIcon}
        contentFit="cover"
        source={require("../assets/FilterIcon.png")}
      />

      {/* <Pressable style={[styles.incompletetask]} onPress={handleIncompleteTask}>
        <Image
          style={styles.incompleteTasksIcon}
          contentFit="cover"
          source={require("../assets/Incomplete_Tasks.png")}
        />
      </Pressable> */}
      {/* <View style={[styles.sort]}>
        <Image
          style={styles.sortbarIcon}
          contentFit="cover"
          source={require("../assets/SortBar.png")}
        />
      </View> */}

      <TouchableOpacity style={[styles.addtaskbtn]} onPress={handleAddTask} >
        <Button style={styles.addtaskbtn} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}>+ Add Task</Text>
      </TouchableOpacity>

      <TouchableOpacity style={[styles.downloadbtn]} onPress={handleExcelDownload} >
        <Button style={styles.downloadbtn} color={'transparent'} ></Button>
        <Text style={{ color: 'black', fontWeight: '600', bottom: 8 }}> ↓ Download excel</Text>
      </TouchableOpacity>
      
    </View>
  );
};

const styles = StyleSheet.create({


  filtersIcon: {
    top: hp(5),
    width: wp(1),
    height: hp(2),
    right: wp(15),
    position: 'absolute'
  },
  filters: {
    width: wp(12),
    right: wp(5),
    height: hp(4),
    top: hp(4),
    alignSelf: 'flex-end',
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: Color.slategray,
    borderWidth: 2,
    borderRadius: 20,
    textAlign: 'center',
    fontSize: '1.2vw',
    color: 'gray',
    fontWeight: '500',
  },


  head: {
    height: 30,
    marginBottom: 5
  },

  headText: {
    margin: hp(7),
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    fontSize: '1vw'
  },

  text: {
    margin: hp(1),
    textAlign: 'center',
    fontSize: '1vw',
    fontWeight: '600'
  },

  row: {
    backgroundColor: '#EFEFEF',
    marginBottom: 15,
    borderRadius: 20,
    width: wp(70),
    height: hp(6),
    opacity: 0.8,
    position: 'relative',

  }, // Set a height for the rows
  background02: {
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background: {
    position: "absolute",
    overflow: "hidden",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },

  selectedDecor: {
    top: 266,
    borderTopRightRadius: Border.br_8xs,
    borderBottomRightRadius: Border.br_8xs,
    backgroundColor: Color.secondaryBlue,
    width: 4,
    height: 64,
  },
  addTask: {
    color: Color.black,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: 20,
  },
  addtaskbtn: {
    backgroundColor: '#D6D6D6',
    top: hp(4),
    paddingVertical: '1%',
    height: hp(3),
    borderWidth: 3,
    borderColor: "#D6D6D6",
    left: wp(23),
    width: wp(10),
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: 'relative',
    overflow: "hidden",
  },

  downloadbtn: {
    width: wp(12),
    right: wp(5),
    height: hp(4),
    top: hp(10),
    alignSelf: 'flex-end',
    position: 'absolute',
    borderColor: '#D6D6D6',
    borderWidth: 2,
    borderRadius: 20,
    alignItems:'center',
    textAlign: 'center',
    fontSize: '1.2vw',
    backgroundColor: '#D6D6D6',
    fontWeight: '500',
  },

  recentlyAssigned: {
    top: hp(10),
    fontSize: 24,
    fontWeight: "800",
    fontFamily: FontFamily.poppinsExtraBold,
    left: wp(23),
    position: "absolute",
    color: Color.white,
    textAlign: "left",
  },
  labels: {
    top: hp(15),
    left: wp(23),
    height: hp(8),
    justifyContent: "space-between",
    overflow: "relative",
    flexDirection: "row",
    position: "absolute",
  },
  incompleteTasksIcon: {
    width: 157,
    height: 24,
  },
  incompletetask: {
    right: wp(7),
    top: hp(10),
    height: '10%',
    justifyContent: "flex-end",
    flexDirection: "row",
    position: "absolute",
  },
  // sortbarIcon: {
  //   width: 57,
  //   height: 24,
  // },
  // sort: {
  //   right: 49,
  //   left: 1321,
  //   top: 90,
  //   height: 10,
  //   justifyContent: "flex-end",
  //   flexDirection: "row",
  //   position: "absolute",
  // },
  tasks: {
    flex: 1,
    backgroundColor: '#0C1824',
    width: wp(100),
    height: hp(100),
    overflow: 'scroll',
  },
});

export default Tasks;
