import * as React from "react";
import { StyleSheet, View, Text,Image, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Color, Border, FontSize, FontFamily, Padding } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";

const ForgotPassword = () => {

const navigation = useNavigation();

const handleChangePassword = () => {
  navigation.navigate("ChangePassword");
}

  return (
    <View style={styles.forgotpass}>
      <View style={[styles.background, styles.backgroundPosition]}>
        <View style={[styles.background01, styles.backgroundPosition]} />
        <LinearGradient
          style={[styles.background02, styles.background02Bg]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)"]}
        />
      </View>
      <LinearGradient
        style={[styles.centercontainer, styles.background02Bg]}
        locations={[0, 0, 1]}
        colors={["rgba(217, 217, 217, 0)", "#143759"]}
      />
      <View style={[styles.contenttxt, styles.profile2FlexBox]}>
        <Text
          style={[styles.dontWorryIt, styles.dontWorryItFlexBox]}
        >{`Don’t worry! It happens. Please enter the phone number we will send the OTP in this phone number. `}</Text>
      </View>

      <View style={styles.titletxt}>
        <Text style={[styles.forgotPassword, styles.dontWorryItFlexBox]}>
          Forgot Password ?
        </Text>
        <Image
          style={styles.decorLineIcon}
          contentFit="cover"
          source={require("../assets/line.png")}
        />
      </View>

      <Pressable style={[styles.continuebtn, styles.phonebxBorder]} onPress={handleChangePassword}>
        <Text style={[styles.continue, styles.continueTypo]}>Continue</Text>
      </Pressable>

      <View style={[styles.phonebx, styles.phonebxBorder]}>
        <Text style={[styles.enterYourPhone, styles.continueTypo]}>
          Enter your Phone Number
        </Text>
      </View>

      <View style={[styles.profile2, styles.profile2FlexBox]}>
        <Image
          style={styles.profileIcon}
          contentFit="cover"
          source={require("../assets/profile.png")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundPosition: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background02Bg: {
    backgroundColor: "transparent",
    position: "absolute",
  },
  profile2FlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    left: "50%",
    position: "absolute",
  },
  dontWorryItFlexBox: {
    textAlign: "center",
    color: Color.white,
  },
  phonebxBorder: {
    borderStyle: "solid",
    borderRadius: 30,
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  continueTypo: {
    textAlign: "center",
    fontSize: 22,
  },
  background01: {
    backgroundColor: "#0C1824",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background02: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    overflow: "hidden",
  },
  centercontainer: {
    top: 186,
    right: 302,
    bottom: 141,
    left: 302,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
  },
  dontWorryIt: {
    fontSize: 20,
    fontFamily: FontFamily.poppinsRegular,
    width: 517,
  },
  contenttxt: {
    marginLeft: -255,
    top: 393,
    padding: 10,
    alignItems: "center",
  },
  forgotPassword: {
    fontSize: 35,
    fontWeight: "700",
    fontFamily: FontFamily.outfitBold,
    height: 41,
    width: 299,
  },
  decorLineIcon: {
    width: 236,
    height: 30,
    marginTop: 21,
  },
  titletxt: {
    marginLeft: -149,
    top: 260,
    width: 299,
    alignItems: "center",
    left: "50%",
    position: "absolute",
  },
  continue: {
    fontWeight: "500",
    fontFamily: FontFamily.outfitMedium,
    color: Color.white,
    textAlign: "center",
    fontSize: FontSize.size_3xl,
  },
  continuebtn: {
    width: "23.75%",
    right: "38.06%",
    bottom: 106,
    left: "38.19%",
    backgroundColor: Color.slategray,
    borderColor: Color.gray,
    borderWidth: 0.6,
    alignItems: "flex-end",
    paddingHorizontal: 130,
    paddingVertical: 21,
  },
  enterYourPhone: {
    fontWeight: "100",
    fontFamily: FontFamily.outfitThin,
    color: Color.gray,
  },
  phonebx: {
    height: "5.86%",
    top: "51.07%",
    right: 315,
    bottom: "43.07%",
    left: 315,
    borderColor: Color.gray,
    borderWidth: 3,
    paddingHorizontal: 358,
    paddingVertical: 29,
    alignItems: "center",
  },
  profileIcon: {
    width: 130,
    height: 130,
  },
  profile2: {
    marginLeft: -70,
    top: 111,
    height: 150,
    paddingHorizontal: 8,
    paddingVertical: 4,
    overflow: "hidden",
  },
  forgotpass: {
    backgroundColor: Color.white,
    flex: 1,
    width: "100%",
    height: 1024,
    overflow: "hidden",
  },
});

export default ForgotPassword;
