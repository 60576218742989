import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,Image } from 'react-native';
import React from 'react';
import Navigation from './Navigation/Navigation';
import TrackM2 from './Track/TrackM2';

const App = () => {
  return (
    <View style={{ flex: 1 }}>
      <Navigation/>
      {/* <TrackM2/> */}
    </View>
  );
};

export default App;
