import React from 'react';
import { View, StyleSheet, WebView, Platform } from 'react-native';

const TrackM2 = () => {
    const renderWebMap = () => {
        // For web platform
        return (
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?q=place_id&key=AIzaSyDdxXuypQf8RkTClMk6Q4iycj76818NfUs"
            allowFullScreen
          ></iframe>
        );
      };
    
      const renderNativeMap = () => {
        // For React Native app
        return (
          <WebView
            source={{ uri: 'https://www.google.com/maps/embed/v1/place?q=place_id&key=AIzaSyDdxXuypQf8RkTClMk6Q4iycj76818NfUs' }}
            style={styles.map}
          />
        );
      };
    
      return (
        <View style={styles.container}>
          {Platform.OS === 'web' ? renderWebMap() : renderNativeMap()}
        </View>
      );
    };
    
    const styles = StyleSheet.create({
      container: {
        flex: 1,
      },
      map: {
        flex: 1,
      },
    });
    

export default TrackM2;