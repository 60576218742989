import * as React from "react";
import { StyleSheet, Text, View, Image, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, Padding, FontSize, Color } from "./GlobalStyles";
import { useRoute } from "@react-navigation/native";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { useNavigation } from "@react-navigation/native";

const Header1 = ({ onPress }) => {
  const route = useRoute();
  const { user } = route.params;

  const navigation = useNavigation();
  const handkeMenuPress = () => {
    onPress();
  }

  const handleTrackM = () => {
    navigation.navigate("TrackM2");
  };

  return (
    <LinearGradient
      style={styles.header}
      locations={[0, 0.46]}
      colors={["rgba(255, 255, 255, 0)", "#d6d6d6"]}
    >
      <View style={styles.userActive}>
        {/* <Image
          style={styles.profilevectorIcon}
          contentFit="cover"
          source={require("./assets/ProfileVector.png")}
        /> */}
        <Text style={[styles.name]}> {user.username}</Text>
        <Text style={[styles.designation]}>
          {user.role}
        </Text>
      </View>

      <Image
        style={styles.marutiGroup}
        contentFit="cover"
        source={require("./assets/Maruti Group.png")}
      />

      <View style={[styles.locationIcon]}>
      <Image
        resizeMode='contain'
        style={styles.locationIcon}
        contentFit="cover"
        source={require("./assets/location.png")}
      />
      </View>

      <Image
        style={styles.notificationsIcon}
        contentFit="cover"
        source={require("./assets/Notifications.png")}
      />

    </LinearGradient>
  );
};

const styles = StyleSheet.create({

  profilevectorIcon: {
    width: wp(3),
    height: hp(5),
  },
  designation: {
    top: hp(2),
    textAlign: "left",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    position: "absolute",
  },
  name: {
    fontSize: 17,
    color: Color.slategray,
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500"
  },
  userActive: {
    width: wp(30),
    top: hp(2),
    right: wp(5),
    alignSelf: 'center',
    justifyContent: "flex-end",
    flexDirection: "row",
    position: "absolute",
  },
  marutiGroup: {
    width: wp(17),
    left: wp(2),
    maxWidth: wp(17),
    height: hp(7),
    alignSelf: 'center',
    position: "absolute",
    overflow: "hidden",
  },
  locationIcon: {
    width: wp(1.5),
    height: hp(3.5),
    right: wp(12),
    top: hp(1.4),
    alignSelf: 'center',
    alignSelf: 'flex-end',
    flexDirection: 'row'
  },
  notificationsIcon: {
    width: wp(2),
    height: hp(4),
    right: wp(20),
    bottom: hp(1),
    alignSelf: 'flex-end',
    flexDirection: 'row'
  },

  header: {
    flex: 1,
    width: wp(100),
    height: hp(10),
    backgroundColor: "transparent",
    overflow: "hidden",
  },
});

export default Header1;