import * as React from "react";
import { StyleSheet, View, Text, Image, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, FontSize, Color, Border } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { TextInput } from "react-native-gesture-handler";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const CustomSideMenu = () => {
  const navigation = useNavigation();

  const handleDashboard = () => {
    navigation.navigate('Dashboard');
  }
  const handleTasks = () => {
    navigation.navigate('Tasks');
  }
  const handleUserManagement = () => {
    navigation.navigate('UserManagement')
  }

  const handleDirectory = () => {
    navigation.navigate('Directory');
  }

  const handleLogout = () => {
    navigation.navigate('SignIn');
  }

  const handleCalendar = () => {
    navigation.navigate('CalendarM');
  }

  

  return (
    <View style={styles.sideMenu}>
      <LinearGradient
        style={styles.centercontainer}
        locations={[0, 0, 1]}
        colors={["#143759", "rgba(217, 217, 217, 0)", "#143759"]}
      />

      <Image
        style={styles.dashboardIcon}
        contentFit="cover"
        source={require("../assets/dashboard.png")}
      />
      <Pressable style={[styles.dashboardtxt]} onPress={handleDashboard}>
        Dashboard
      </Pressable>

      <Image
        style={styles.taskIcon}
        contentFit="cover"
        source={require("../assets/task.png")}
      />
      <Pressable style={[styles.taskstxt]} onPress={handleTasks}>Tasks</Pressable>


      <Image
        style={styles.calendarIcon}
        contentFit="cover"
        source={require("../assets/calendar.png")}
      />
      <Pressable style={[styles.calendartxt]} onPress={handleCalendar}>Calendar</Pressable>


      <Image
        style={styles.performanceIcon}
        contentFit="cover"
        source={require("../assets/performance.png")}
      />
      <Pressable style={[styles.performancetxt]}>
        Performance
      </Pressable>

      <Image
        style={styles.directoryIcon}
        contentFit="cover"
        source={require("../assets/customers.png")}
      />
      <Pressable style={styles.directorytxt} onPress={handleDirectory}>Directory</Pressable>

      <Image
          style={styles.userControlIcon}
          contentFit="cover"
          source={require("../assets/Users.png")}
        />
        <Pressable style={[styles.userControltxt]} onPress={handleUserManagement}>
          User Control
        </Pressable>


      <Image
        style={styles.settingsIcon}
        contentFit="cover"
        source={require("../assets/settings.png")}
      />
      <Pressable style={[styles.settingstxt]}>Settings</Pressable>

        <Image
          style={styles.logoutIcon}
          contentFit="cover"
          source={require("../assets/logout.png")}
        />
        <Pressable style={[styles.logouttxt]} onPress={handleLogout}>Logout</Pressable>

    </View>
  );
};

const styles = StyleSheet.create({
  tasksPosition: {

  },
  tasks1Typo: {
    marginLeft: 38,
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: FontSize.size_5xl,
  },
  calendarIcon: {
    top: '23.5%',
    width: 33,
    height: 33,
    left: '-163%',
    position: 'relative'
  },
  calendartxt: {
    top: '24%',
    left: '-158%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  centercontainer: {
    alignSelf: "stretch",
    borderBottomRightRadius: 30,
    backgroundColor: "transparent",
    zIndex: 0,
    width: '100%',
  },
  dashboardtxt: {
    top: '5%',
    left: '-70%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  dashboardIcon: {
    top: '5%',
    width: 30,
    height: 30,
    left: '-75%',
    position: 'relative',
  },
  taskIcon: {
    top: '14%',
    width: 35,
    height: 35,
    left: '-128.5%',
    position: 'relative',
  },
  taskstxt: {
    top: '15%',
    left: '-123%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  performanceIcon: {
    top: '32.5%',
    width: 33,
    height: 33,
    left: '-210%',
    position: 'relative'
  },
  performancetxt: {
    top: '33%',
    left: '-205%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  directoryIcon: {
    top: '41%',
    width: 33,
    height: 33,
    left: '-270.5%',
    position: 'relative'
  },
  directorytxt: {
    top: '41%',
    left: '-265.5%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  settingsIcon: {
    top: '80%',
    width: 33,
    height: 33,
    left: '-379%',
    position: 'relative'
  },
  settingstxt: {
    top: '80.5%',
    left: '-374%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  logoutIcon: {
    top: '88%',
    width: 33,
    height: 33,
    left: '-421%',
    position: 'relative'
  },
  logouttxt: {
    top: '88.5%',
    left: '-417%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  userControlIcon: {
    top: '49%',
    width: 30,
    height: 30,
    left: '-319%',
    position: 'relative',
  },
  userControltxt: {
    top: '49.5%',
    left: '-313%',
    textAlign: "left",
    fontFamily: FontFamily.outfitMedium,
    fontWeight: "500",
    fontSize: 24,
    color: '#fff',
    position: 'relative',
  },
  sideMenu: {
    top: hp(7.8),
    height: hp(90),
    overflow: 'hidden',
    flexDirection: "row",
    width: "80%",
    marginLeft: '90%',
    position:'absolute',
    alignSelf:'flex-start'
  },
});

export default CustomSideMenu;