import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, View, Text, Pressable, Image, TextInput, TouchableOpacity, FlatList, Button } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, Padding, Color, Border, FontSize } from "../GlobalStyles";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { API_URL } from "../Config";
import { Dropdown } from "react-native-element-dropdown";
import axios from "axios";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';



const UserUpdate = () => {

    const navigation = useNavigation();
    const route = useRoute();
    const { users } = route.params;
    const [userUpdate, setUserUpdate] = useState({ ...users });
    const [loading, setLoading] = useState(false);

    const [bloodValue, setBloodValue] = useState(null);
    const [isBloodFocus, setIsBloodFocus] = useState(false);
    const blooddata = [
        'A+', 'O+', 'B+', 'AB+','A-','O-','B-','AB-'
    ];

    const [roleValue, setRoleValue] = useState(null);
    const [isRoleFocus, setIsRoleFocus] = useState(false);
    const roledata = [
        'Admin', 'HR', 'Manager', 'Employee', 'Fitter','Operator','Helper','Mistri','Machine Operator','Measurement Executive','Painter','Hardware Store Boy',
        'Production Head','Site Supervisor','Supplier','Material Management','Asst. Store Manager','Senior Supervisor','Office Boy','Senior Purchase Executive',
        'Production Manager','Driver','Quotation & Account Manager','Account Head','Site Measurement Executive','Designer','Business Manager','CBO- Chief Business Officer',
        'Director','CMO - Chief Marketing Officer'
    ];


    const updateTask = async () => {
    setLoading(true);
        try {
            const response = await axios.put(API_URL + `/user/${users.username}`, userUpdate);

            if (response.status === 200) {
                console.log('User updated successfully');
                navigation.navigate('UserManagement');
                window.alert('User updated successfully!')
                setLoading(false);
            } else {
                console.error('Error updating user');
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const deleteUser = async () => {
        try {
            const response = await axios.delete(API_URL + `/users/${users.username}`);

            if (response.status === 204) {
                window.alert('User deleted successfully!')
                navigation.navigate('UserManagement');
            } else {
                console.error('Error deleting User');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTaskDetails = () => {
        axios.get(API_URL + `/users/${users.username}`)
            .then(response => setUserUpdate(response.data))
            .catch(error => console.error(error));
    };
    useFocusEffect(
        useCallback(() => {
            fetchTaskDetails();
        }, [users.username])
    );



    return (
        <View style={styles.register}>
            <View style={[styles.background]}>
                <LinearGradient
                    style={[styles.background02Bg]}
                    locations={[0.2, 1]}
                    colors={["#173452", "rgba(23, 52, 82, 0)", "#173452"]}
                />
            </View>


            <View style={styles.trash}>
                    <TouchableOpacity style={styles.deleteButton} onPress={deleteUser}>
                        <Image
                            style={styles.trashIcon}
                            contentFit="cover"
                            source={require("../assets/trash.png")}
                        />
                    </TouchableOpacity>
                </View>

            <View
                style={[styles.centercontainer]}
            >

                <Text style={[styles.registerAccount]}>
                    User Details
                </Text>

                <View style={{ flex: 1, top: '10%' }}>
                    <View style={[styles.namebx]}>
                        <Text style={[styles.name, {color:"#D6D6D6"}]}>Name : </Text>
                        <TextInput style={[styles.name]}
                            placeholder="Name"
                            value={userUpdate.username}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, username: text })} ></TextInput>
                    </View>

                    <View style={[styles.rolebx]}>
                    <Text style={[styles.role, {color:"#D6D6D6"}]}>Role : </Text>
                         <Dropdown
                        style={[styles.role, isRoleFocus]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        iconStyle={styles.iconStyle}
                        containerStyle={styles.prioritycontainerstyle}
                        itemTextStyle={styles.itemprioritycontainerstyle}
                        data={roledata.map((label, index) => ({ label, value: (index + 1).toString() }))}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isRoleFocus ? `${userUpdate.role}` : '...'}
                        value={roleValue}
                        onFocus={() => setIsRoleFocus(true)}
                        onBlur={() => setIsRoleFocus(false)}
                        onChange={item => {
                            setRoleValue(item.value);
                            setUserUpdate({ ...userUpdate, role: item.label })
                            setIsRoleFocus(false);
                        }}
                    />
                    </View>

                    <View style={[styles.phonebx]}>
                    <Text style={[styles.phone, {color:"#D6D6D6"}]}>Phone : </Text>
                        <TextInput style={[styles.phone]}
                            placeholder="Phone"
                            value={userUpdate.mobile}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, mobile: text })}></TextInput>
                    </View>

                    <View style={[styles.emailbx]}>
                    <Text style={[styles.email, {color:"#D6D6D6"}]}>Email : </Text>
                        <TextInput style={[styles.email]}
                            placeholder="Email"
                            value={userUpdate.email}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, email: text })}></TextInput>
                    </View>

                    <View style={[styles.managerbx]}>
                    <Text style={[styles.manager, {color:"#D6D6D6"}]}>Manager : </Text>
                        <TextInput style={[styles.manager]}
                            placeholder="Manager"
                            value={userUpdate.manager}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, manager: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.Referralbx]}>
                    <Text style={[styles.Referral, {color:"#D6D6D6"}]}>Referral Name : </Text>
                        <TextInput style={[styles.Referral]}
                            placeholder="Referral Name"
                            value={userUpdate.reference_name}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, reference_name: text })}></TextInput>
                    </View>

                    <View style={[styles.RGDatebx]}>
                    <Text style={[styles.RGDate, {color:"#D6D6D6"}]}>Date of Joining : </Text>
                        <TextInput style={[styles.RGDate]}
                            placeholder="Date of Joining"
                            value={userUpdate.date_of_join}
                            placeholderTextColor={Color.white}
                        onChangeText={text => setUserUpdate({ ...userUpdate, date_of_join: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.addressbx]}>
                    <Text style={[styles.address, {color:"#D6D6D6"}]}>Address : </Text>
                        <TextInput style={[styles.address]}
                            placeholder="Address"
                            value={userUpdate.address}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, address: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.DOBbx]}>
                    <Text style={[styles.DOB, {color:"#D6D6D6"}]}>Date Of Birth : </Text>
                        <TextInput style={[styles.DOB]}
                            placeholder="Date Of Birth"
                            value={userUpdate.date_of_birth}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, date_of_birth: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.IDbx]}>
                    <Text style={[styles.ID, {color:"#D6D6D6"}]}>Identity Card Details : </Text>
                        <TextInput style={[styles.ID]}
                            placeholder="Identity Card Details"
                            value={userUpdate.id_proof_number}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, id_proof_number: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.IDUploadbx]}>
                    <Text style={[styles.IDUpload, {color:"#D6D6D6"}]}>Identity Card Details : </Text>
                        <Button style={[styles.IDUpload]}
                            title="⇪  Identity Card Upload"
                            color={'transparent'}
                        // placeholderTextColor={Color.white}
                        ></Button>
                    </View>


                    <View style={[styles.Bloodbx]}>
                    <Text style={[styles.Blood, {color:"#D6D6D6"}]}>Blood Group : </Text>
                         <Dropdown
                        style={[styles.Blood, isBloodFocus]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        iconStyle={styles.iconStyle}
                        containerStyle={styles.prioritycontainerstyle}
                        itemTextStyle={styles.itemprioritycontainerstyle}
                        data={blooddata.map((label, index) => ({ label, value: (index + 1).toString() }))}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isBloodFocus ? `${userUpdate.blood_group}` : '...'}
                        value={bloodValue}
                        onFocus={() => setIsBloodFocus(true)}
                        onBlur={() => setIsBloodFocus(false)}
                        onChange={item => {
                            setBloodValue(item.value);
                            setUserUpdate({ ...userUpdate, blood_group: item.label })
                            setIsBloodFocus(false);
                        }}
                    />
                    </View>

                    <View style={[styles.WorkTypebx]}>
                    <Text style={[styles.WorkType, {color:"#D6D6D6"}]}>Work Type : </Text>
                        <TextInput style={[styles.WorkType]}
                            placeholder="Work Type"
                            value={userUpdate.work_type}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUserUpdate({ ...userUpdate, work_type: text })}
                        ></TextInput>
                    </View>
                    
                    {loading ? <View style={styles.registerbtnDisable} >
                        <Text style={styles.registertxt}>Update User</Text>
                    </View> : <TouchableOpacity style={styles.registerbtn} onPress={updateTask} >
                        <Text style={styles.registertxt}>Update User</Text>
                    </TouchableOpacity> }
                    


                </View>
            </View>

        </View>

    );
};


const styles = StyleSheet.create({

    registertxt: {
        fontSize: 18,
        fontWeight: "700",
        fontFamily: FontFamily.outfitMedium,
        color: "white",
        textAlign: "center",
        bottom: 10
    },

    registerbtn: {
        width: "20%",
        height: 20,
        bottom: '60%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },

    registerbtnDisable: {
        opacity:0.5,
        width: "20%",
        height: 20,
        bottom: '60%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },


    dropdown: {
        height: 31,
        width: 160,
        backgroundColor: "#D6D6D6",
        borderRadius: 30,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    selectedTextStyle: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    prioritycontainerstyle: {
        backgroundColor: "#D6D6D6",
        color: 'white',
        borderRadius: 30,
        textAlign: 'center',
        borderWidth: 3,
        width: '1000%',
        alignSelf: 'center'
    },
    itemprioritycontainerstyle: {
        textAlign: 'center',
        color: 'black',
    },

    decorLineIcon: {
        width: 200,
        height: 25,
        top: '-24%',
        left: '43%',
    },

    registerAccount: {
        top: '-15%',
        width: '80%',
        fontSize: '2vw',
        left: '10%',
        fontWeight: "600",
        fontFamily: FontFamily.outfitMedium,
        textAlign: "center",
        color: Color.white,
        position: 'relative'
    },

    uploadbtnIcon: {
        width: 150,
        height: 150,
        top: '-20%',
        alignSelf: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    DOBbx: {
        top: '-52.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    DOB: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    IDbx: {
        top: '-48.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    ID: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    IDUploadbx: {
        top: '-57%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    IDUpload: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        backgroundColor: 'transparent'
    },

    Referralbx: {
        top: '-38.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    Referral: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    emailbx: {
        top: '-33.5%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        right: "3%",
        width: "45%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    email: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    managerbx: {
        top: '-30%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    manager: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    phonebx: {
        top: '-25%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "45%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    phone: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    RGDatebx: {
        top: '-47.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    RGDate: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    rolebx: {
        top: '-28.5%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        right: "3%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    role: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    Bloodbx: {
        top: '-66%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        right: "2%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    Blood: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    namebx: {
        top: '-20%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "60%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    name: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    addressbx: {
        top: '-43.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "60%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    address: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    trashIcon: {
        width: wp(1),
        height: hp(2.5),
    },

    trash: {
        top: hp(8),
        right: wp(10),
        flexDirection: "row",
        position: "absolute",
    },

    WorkTypebx: {
        top: '-63%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    WorkType: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    centercontainer: {
        top: '20%',
        // right: '0%',
        bottom: 10,
        left: '17.5%',
        height: '80%',
        width: '80%',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        backgroundColor: "transparent",
        position: "relative",
    },

    background02Bg: {
        backgroundColor: "transparent",
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    background: {
        position: "absolute",
        overflow: "hidden",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    register: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: 'scroll',
        backgroundColor: "#0C1824",
    },

});


export default UserUpdate;
