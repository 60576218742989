import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, FontSize, Border, Color, Padding } from "../GlobalStyles";
import { TextInput } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import axios from 'axios';
import { useNavigation, useRoute } from "@react-navigation/native";
import { API_URL } from '../Config';
import Calendar from 'react-calendar';
import { render } from 'react-dom';
import { Dropdown } from 'react-native-element-dropdown';
import DateTimePicker from '@react-native-community/datetimepicker';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import * as ImagePicker from 'expo-image-picker';




const AddTask = () => {

  const route = useRoute();
  const { user } = route.params;

  const navigation = useNavigation();

  const [assignee, setAssignee] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [status, setStatus] = useState('');
  const [deadline, setDeadline] = useState('');
  const [taskDetails, setTaskDetails] = useState('');
  const [priority, setPriority] = useState('');

  const [clientName, setClientName] = useState('');
  const [siteName, setSiteName] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [emailId, setEmailId] = useState('');
  const [loading, setLoading] = useState(false);

  const [profileImage, setProfileImage] = useState('');

  const openImageLibrary = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
    }

    if (status === 'granted') {
      const response = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
      });
      let uri;
      if (!response.cancelled) {
        if (response) {
          response.assets.forEach(i => {
            if (i.uri) {
              uri = i.uri;
            }
          })
        }

        if (uri !== profileImage) {
          setProfileImage(uri);
          console.log("profile Image===>", profileImage);
        }
      }
    }
  };

  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetch(API_URL + '/users')
      .then(response => response.json())
      .then(data => setUsers(data))
      .catch(error => console.error(error));
  }, []);
  const [client, setClient] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/client')
      .then(response => response.json())
      .then(data => setClient(data))
      .catch(error => console.error(error));
  }, []);


  const siteAddress = address;
  const siteMobile = contactNo;
  const siteEmail = emailId;

  const resetState = () => {
    setAssignee('');
    setAssigneeValue(null);
    setClientValue(null);
    setPriorityValue(null);
    setStatusValue(null);
    setCategoryValue(null);
    setSubCategoryValue(null);
    setSiteName('');
    setAddress('');
    setContactNo('');
    setEmailId('');
    setCategory('');
    setSubCategory('');
    setStatus('');
    setDeadline('');
    setTaskDetails('');
    setPriority('');
  }
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      resetState();
    });

    return unsubscribe;
}, [navigation]);
  const handleAddTask = () => {
    setLoading(true);
      console.log("log==>",user.username);
    const formData = new FormData();
    formData.append('assignee', assignee);
    formData.append('category', category);
    formData.append('status', status);
    formData.append('deadline', deadline);
    formData.append('taskDetails', taskDetails);
    formData.append('priority', priority);
    formData.append('clientName', clientName);
    formData.append('siteName', siteName);
    formData.append('siteAddress', siteAddress);
    formData.append('siteMobile', siteMobile);
    formData.append('siteEmail', siteEmail);
    formData.append('subCategory', subCategory);
    formData.append('assignedFrom', user.username);

    const base64Image = profileImage.replace(/^data:image\/\w+;base64,/, '');
    const byteCharacters = atob(base64Image); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    formData.append('photo', blob, 'profile.jpg');
    formData.append('username', user.username);

    // const newTask = { assignee, category, status, deadline, taskDetails, priority, clientName, siteName, siteAddress, siteMobile, siteEmail, subCategory };
    // Make a POST request to the backend API to add the task
    axios.post(API_URL + '/tasks', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        resetState();
        navigation.navigate('Tasks');
        setLoading(false);
      })
      .catch(error => {
        // Handle error
        console.error(error);
        setLoading(false);
      });

  };

  const handleGallery = () => {
    navigation.navigate('Gallery')
  };

  const [assigneeValue, setAssigneeValue] = useState(null);
  const [isAssigneeFocus, setIsAssigneeFocus] = useState(null);
  const assigneeData = [];
  if (users) {
    users.forEach(i => {
      assigneeData.push(i.username);
    })
  }


  const [clientValue, setClientValue] = useState(null);
  const [isClientFocus, setIsClientFocus] = useState(false);
  const clientdata = [];
  if (client) {
    client.forEach(i => {
      clientdata.push(i.clientName);
    })
  }

  const [priorityValue, setPriorityValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const prioritydata = [
    'Low', 'Medium', 'High'
  ];


  const [statusValue, setStatusValue] = useState(null);
  const [isStatusFocus, setIsStatusFocus] = useState(false);
  const statusdata = [
    'Pending', 'In-Process', 'Completed'
  ];


  const [categoryValue, setCategoryValue] = useState(null);
  const [isCategoryFocus, setIsCategoryFocus] = useState(false);
  const categorydata = [
    'Service', 'Installation', 'Site Visit'
  ];

  const [subcategoryValue, setSubCategoryValue] = useState(null);
  const [isSubCategoryFocus, setIsSubCategoryFocus] = useState(false);
  const subcategorydata = [
    'POP', 'Furniture', 'Lights'
  ];

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flexGrow: 1 }}
      keyboardShouldPersistTaps="handled">
      <View style={styles.addTask}>
        <View style={[styles.background, styles.backgroundPosition]}>
          <View style={[styles.background01, styles.backgroundPosition]} />
          <LinearGradient
            style={[styles.background02, styles.headerPosition]}
            locations={[0.2, 1]}
            colors={["#173452", "rgba(23, 52, 82, 0)"]}
          />
        </View>
        {/* <View style={[styles.selected, styles.backgroundPosition]} /> */}

        <View style={[styles.selectionOptn]}>
          <Text style={[styles.selectAnAssignee]}>
            Select an Assignee
          </Text>
        </View>

        <View style={[styles.AddTask]}>
          <Text style={[styles.selectAnAssignee]}>
            Add Task
          </Text>
        </View>

        {loading ? <View style={[styles.savebtnDisable]}>
          <Text style={[styles.save, styles.saveTypo]}>Save</Text>
        </View> : <TouchableOpacity style={[styles.savebtn]} onPress={handleAddTask}>
          <Text style={[styles.save, styles.saveTypo]}>Save</Text>
        </TouchableOpacity>}


        <View style={[styles.addlocationbtn]}>
          <Text style={[styles.save, styles.saveTypo]}>Add Location</Text>
        </View>

        {/* <View style={[styles.ddbx, styles.assigneebx]}>
          <Image
            style={styles.downarrowIcon}
            contentFit="cover"
            source={require("../assets/downarrow.png")}
          />
        </View> */}
        <View style={[styles.assigneebx]}>
          <Dropdown
            style={[styles.dropdown, isAssigneeFocus]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            iconStyle={styles.iconStyle}
            containerStyle={styles.prioritycontainerstyle}
            itemTextStyle={styles.itemprioritycontainerstyle}
            data={assigneeData.map((label, index) => ({ label, value: (index + 1).toString() }))}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isAssigneeFocus ? 'Assignee' : '...'}
            value={assigneeValue}
            onFocus={() => setIsAssigneeFocus(true)}
            onBlur={() => setIsAssigneeFocus(false)}
            onChange={item => {
              setAssigneeValue(item.value);
              setAssignee(item.label);
              setIsAssigneeFocus(false);
            }}
          />

        </View>

        <View style={styles.detailsbx}>
          <TextInput style={[styles.addTaskDetails]}
            placeholder="Add Task Details.."
            value={taskDetails}
            placeholderTextColor={Color.black}
            onChangeText={text => setTaskDetails(text)}>
          </TextInput>
        </View>


        <TextInput style={[styles.duedatebx]}
          value={deadline}
          placeholder='Due Date'
          placeholderTextColor='black'
          onChangeText={text => setDeadline(text)}
        />


        <View style={[styles.duedateoptn]}>
          <Text style={[styles.selectAnAssignee]}>Due Date</Text>
        </View>

        <View style={[styles.prioritybx]}>
          {/* <TextInput style={[styles.prioritybx1, styles.ddbx1Layout]} placeholder='Priority'
            value={priority}
            placeholderTextColor={Color.black}
            onChangeText={text => setPriority(text)}>
          </TextInput> */}

          <Dropdown
            style={[styles.dropdown, isFocus]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            iconStyle={styles.iconStyle}
            containerStyle={styles.prioritycontainerstyle}
            itemTextStyle={styles.itemprioritycontainerstyle}
            data={prioritydata.map((label, index) => ({ label, value: (index + 1).toString() }))}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isFocus ? 'Priority' : '...'}
            value={priorityValue}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={item => {
              setPriorityValue(item.value);
              setPriority(item.label);
              setIsFocus(false);
            }}
          />
        </View>

        <View style={[styles.priorityoptn]}>
          <Text style={[styles.selectAnAssignee]}>Priority</Text>
        </View>

        <View style={[styles.statusbx]}>


          {/* <TextInput style={[styles.prioritybx1, styles.ddbx1Layout]}
            placeholder="Status"
            value={status}
            placeholderTextColor={Color.black}
            onChangeText={text => setStatus(text)}>
          </TextInput> */}

          <Dropdown
            style={[styles.dropdown, isStatusFocus]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            iconStyle={styles.iconStyle}
            containerStyle={styles.prioritycontainerstyle}
            itemTextStyle={styles.itemprioritycontainerstyle}
            data={statusdata.map((label, index) => ({ label, value: (index + 1).toString() }))}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isStatusFocus ? 'Status' : '...'}
            value={statusValue}
            onFocus={() => setIsStatusFocus(true)}
            onBlur={() => setIsStatusFocus(false)}
            onChange={item => {
              setStatusValue(item.value);
              setStatus(item.label);
              setIsStatusFocus(false);
            }}
          />

        </View>

        <View style={[styles.stageoptn]}>
          <Text style={[styles.selectAnAssignee]}>Status</Text>
        </View>

        <View style={[styles.categorybx]}>
          {/* <TextInput style={[styles.prioritybx1, styles.ddbx1Layout]}
            placeholder="Category"
            value={category}
            placeholderTextColor={Color.black}
            onChangeText={text => setCategory(text)}>
          </TextInput> */}

          <Dropdown
            style={[styles.dropdown, isCategoryFocus]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            iconStyle={styles.iconStyle}
            containerStyle={styles.prioritycontainerstyle}
            itemTextStyle={styles.itemprioritycontainerstyle}
            data={categorydata.map((label, index) => ({ label, value: (index + 1).toString() }))}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isCategoryFocus ? 'Category' : '...'}
            value={categoryValue}
            onFocus={() => setIsCategoryFocus(true)}
            onBlur={() => setIsCategoryFocus(false)}
            onChange={item => {
              setCategoryValue(item.value);
              setCategory(item.label);
              setIsCategoryFocus(false);
            }}
          />

        </View>


        <View style={[styles.Subcategorybx]}>
          {/* <TextInput style={[styles.prioritybx1, styles.ddbx1Layout]}
            placeholder="Category"
            value={category}
            placeholderTextColor={Color.black}
            onChangeText={text => setCategory(text)}>
          </TextInput> */}

          <Dropdown
            style={[styles.dropdown, isSubCategoryFocus]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            iconStyle={styles.iconStyle}
            containerStyle={styles.prioritycontainerstyle}
            itemTextStyle={styles.itemprioritycontainerstyle}
            data={subcategorydata.map((label, index) => ({ label, value: (index + 1).toString() }))}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isSubCategoryFocus ? 'Sub Category' : '...'}
            value={subcategoryValue}
            onFocus={() => setIsSubCategoryFocus(true)}
            onBlur={() => setIsSubCategoryFocus(false)}
            onChange={item => {
              setSubCategoryValue(item.value);
              setSubCategory(item.label);
              setIsSubCategoryFocus(false);
            }}
          />

        </View>

        <View style={[styles.categoryoptn]}>
          <Text style={[styles.selectAnAssignee]}>Category</Text>
        </View>

        <View style={[styles.Subcategoryoptn]}>
          <Text style={[styles.selectAnAssignee]}>Sub Category</Text>
        </View>


        <Text style={[styles.selectAnClient]}>
          Select an Client
        </Text>


        <View style={[styles.clientbx]}>
          <Dropdown
            style={[styles.dropdown, isClientFocus]}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            iconStyle={styles.iconStyle}
            containerStyle={styles.prioritycontainerstyle}
            itemTextStyle={styles.itemprioritycontainerstyle}
            data={clientdata.map((label, index) => ({ label, value: (index + 1).toString() }))}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isClientFocus ? 'Client Name' : '...'}
            value={clientValue}
            onFocus={() => setIsClientFocus(true)}
            onBlur={() => setIsClientFocus(false)}
            onChange={item => {
              setClientValue(item.value);
              // Set the selected client data
              const selectedClient = client.find(c => c.clientName === item.label);
              if (selectedClient) {
                setClientName(selectedClient.clientName);
                setSiteName(selectedClient.siteName);
                setAddress(selectedClient.siteAddress);
                setContactNo(selectedClient.siteMobile);
                setEmailId(selectedClient.siteEmail);
              }
              setIsClientFocus(false);
            }}
          />
        </View>

        <Text style={[styles.Sitename]}>Site Name</Text>
        <Text style={[styles.Sitenametxt]}>{siteName}</Text>

        <Text style={[styles.Address]}>Address</Text>
        <Text style={[styles.Addresstxt]}>{address}</Text>

        <Text style={[styles.Contact]}>Contact No.</Text>
        <Text style={[styles.Contacttxt]}>{contactNo}</Text>

        <Text style={[styles.Email]}>Email ID</Text>
        <Text style={[styles.Emailtxt]}>{emailId}</Text>

        <View style={[styles.documentsoptn,]}>
          <Text style={[styles.viewDocuments]}>
            Documents
          </Text>
        </View>


        {/* <View style={styles.documents}>
          <View style={styles.d1FlexBox}>
            <Image style={styles.upload} contentFit="cover"
              source={require("../assets/uploadP.png")} />
          </View>
          <View style={[styles.d2, styles.d1FlexBox]}>
            <View style={styles.doc4} />
          </View>
        </View> */}
        <Pressable style={styles.documents} onPress={openImageLibrary}>
          <View style={styles.d1FlexBox}>
            {profileImage ? (<Image style={styles.upload} contentFit="cover"
              source={{ uri: profileImage }} />) : (<Image style={styles.upload} contentFit="cover"
                source={require("../assets/uploadP.png")} />)}
          </View>
        </Pressable>

        <Pressable style={styles.documentsview} >
          <View style={[styles.d2, styles.d1FlexBox]}>
            <View style={styles.doc4} />
          </View>
        </Pressable>

      </View>
    </KeyboardAwareScrollView >
  );
};

// render(<ReactCalendar/>, document.querySelector("#root"));

const styles = StyleSheet.create({


  documents: {
    top: hp(55),
    right: wp(37),
    height: hp(4),
    flexDirection: "row",
    position: "absolute",
  },
  documentsview: {
    top: hp(55),
    right: wp(33),
    height: hp(4),
    flexDirection: "row",
    position: "absolute",
  },

  d1FlexBox: {
    justifyContent: "space-between",
    width: 40,
    flexDirection: "row",
  },

  upload: {
    width: 40,
    height: 40,
  },
  d2: {
    marginLeft: 22,
  },
  doc4: {
    backgroundColor: "white",
    width: 40,
    height: 40,
  },

  backgroundPosition: {
    left: 0,
    position: "absolute",
  },
  selectAnClient: {
    top: hp(36.5),
    right: wp(21),
    width: wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: '1vw'
  },
  clientselection: {
    top: hp(36.5),
    right: wp(22),
    width: wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: '1vw'
  },
  clientbx: {
    top: hp(39.5),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',

  },
  duedatebx: {
    top: hp(22.5),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },

  dropdown: {
    height: 31,
    width: 160,
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  selectedTextStyle: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  prioritycontainerstyle: {
    backgroundColor: "#D6D6D6",
    color: 'black',
    borderRadius: 5,
    textAlign: 'center',
  },
  itemprioritycontainerstyle: {
    textAlign: 'center',
  },


  Sitename: {
    top: hp(52),
    justifyContent: "center",
    left: wp(20),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "500",
    position: "absolute",
    color: Color.white,
    textAlign: "left",
  },
  Sitenametxt: {
    top: hp(54.5),
    left: wp(20),
    height: hp(4),
    width: wp(30),

    paddingHorizontal: 8,
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  Address: {
    top: hp(68),
    fontWeight: "500",
    fontSize: '1vw',
    left: wp(20),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    position: "absolute",
    color: Color.white,
  },
  Addresstxt: {
    top: hp(70.5),
    left: wp(20),
    width: wp(70),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },

  Contact: {
    top: hp(44.5),
    right: wp(22),
    width: wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: '1vw'
  },
  Contacttxt: {
    top: hp(47),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  Email: {
    top: hp(60),
    fontSize: 14,
    // alignSelf: "center",
    left: wp(20),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "500",
    fontSize: '1vw',
    position: "absolute",
    color: Color.white,
  },
  Emailtxt: {
    top: hp(62.5),
    left: wp(20),
    width: wp(70),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },

  headerPosition: {
    backgroundColor: "transparent",
    left: 0,
    right: 0,
    top: 0,
    position: "absolute",
  },

  viewDocuments: {
    color: Color.white,
    fontWeight: "500",
    fontSize: '1vw'
  },
  documentsoptn: {
    top: hp(52),
    fontSize: '1vw',
    right: wp(34.5),
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "500",
    position: "absolute",
    color: Color.white,
  },

  saveTypo: {
    textAlign: "left",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: FontSize.size_xs,
  },
  downarrowFlexBox: {
    justifyContent: "flex-end",
    position: "absolute",
  },
  nameTypo: {
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "400",
    fontStyle: "normal",
    textAlign: "left",
    color: Color.black,
  },
  ddbxPosition: {
    left: 26,
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  ddbx1Layout: {
    backgroundColor: "#D6D6D6",
    height: 30,
    borderRadius: 5,
    overflow: 'hidden',
  },
  locationsFlexBox: {
    justifyContent: "space-between",
    padding: 10,
    flexDirection: "row",
    top: 0,
    position: "absolute",
    overflow: "hidden",
  },
  background01: {
    backgroundColor: "#0C1824",
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
  },
  background02: {
    bottom: 0,
  },
  background: {
    bottom: -10,
    right: 0,
    top: 0,
    left: 0,
    overflow: "hidden",
  },
  selected: {
    height: "8%",
    top: "35.25%",
    bottom: "56.75%",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "white",
    width: 4,
  },
  trashIcon: {
    width: 35,
    height: 22,
  },
  trash: {
    top: 30,
    left: 14,
    flexDirection: "row",
    position: "absolute",
  },
  selectAnAssignee: {
    color: Color.white,
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "600",
    textAlign: "left",
    fontSize: '1vw',
  },
  selectionOptn: {
    top: hp(20),
    left: wp(20),
    fontSize: '1vw',
    textAlign: "left",
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "600",
    position: "absolute",
    textAlign: "left",
    color: Color.white,
  },
  detailsbx: {
    width: wp(70),
    top: hp(10),
    left: wp(20),
    backgroundColor: "#D6D6D6",
    height: hp(8),
    paddingVertical: 4,
    borderRadius: 5,
    justifyContent: "center",
    alignSelf: 'center',
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
    textAlign: 'center',
    color: 'black'
  },

  AddTask: {
    top: hp(7),
    left: wp(50),
    justifyContent: "center",
    fontSize: '1vw',
    textAlign: "center",
    alignSelf: 'center',
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "400",
    position: "absolute",
    overflow: "hidden",
    flexDirection: "row",

  },
  save: {
    color: Color.black,
    textAlign: "centre",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
  },
  savebtn: {
    right: wp(10),
    paddingHorizontal: 32,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingVertical: 9,
    borderRadius: 21,
    bottom: hp(10),
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  savebtnDisable: {
    opacity: 0.5,
    right: wp(10),
    paddingHorizontal: 32,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingVertical: 9,
    borderRadius: 21,
    bottom: hp(10),
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  addlocationbtn: {
    left: wp(20),
    paddingHorizontal: 32,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 9,
    borderRadius: 21,
    bottom: hp(10),
    backgroundColor: "white",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  name: {
    fontSize: FontSize.size_xs,
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontWeight: "400",
    fontStyle: "normal",
  },
  downarrowIcon: {
    width: 10,
    height: 10,
  },
  dropdownarrow: {
    width: 29,
    marginLeft: 37,
    padding: 10,
  },
  namebtn: {
    width: "81.11%",
    top: 235,
    right: "11.39%",
    left: 26,
    flexDirection: "row",
    position: "absolute",
  },
  addTaskDetails: {
    fontSize: 18,
    fontWeight: "500",
    fontFamily: FontFamily.poppinsExtraLightItalic,
    fontStyle: "normal",
    textAlign: "left",
    color: Color.black,
  },

  downarrow: {
    width: "23.33%",
    bottom: 1,
    left: "76.67%",
    right: "0%",
    padding: 10,
  },

  assigneebx: {
    top: hp(22.5),
    width: wp(30),
    left: wp(20),
    height: hp(4),
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center'
  },
  duedateoptn: {
    top: hp(20),
    right: wp(10),
    width: wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: '1vw'
  },

  prioritybx: {
    top: hp(31),
    width: wp(30),
    right: wp(10),
    height: hp(4),
    alignItems: "center",
    alignSelf: 'center',
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center',
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    paddingTop: 6,
    fontSize: 16,
    color: 'black',
  },
  priorityoptn: {
    top: hp(28),
    right: wp(10),
    width: wp(30),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.poppinsMedium,
    fontWeight: "500",
    fontSize: '1vw'
  },
  statusbx: {
    top: hp(47),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    paddingHorizontal: 8,
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center'
  },
  stageoptn: {
    top: hp(44.5),
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  categorybx: {
    top: hp(30.5),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    backgroundColor: "#D6D6D6",
    paddingHorizontal: 10,
    borderRadius: 5,
    overflow: "hidden",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center'
  },

  Subcategorybx: {
    top: hp(39.5),
    left: wp(20),
    height: hp(4),
    width: wp(30),
    backgroundColor: "#D6D6D6",
    borderRadius: 5,
    overflow: "hidden",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    textAlign: 'center'
  },
  categoryoptn: {
    top: hp(28),
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  Subcategoryoptn: {
    top: hp(36.5),
    left: wp(20),
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
  },
  locationIcon: {
    width: 17,
    height: 22,
  },
  locations: {
    width: "39.79%",
    right: "60.21%",
    left: "0%",
    height: 42,
  },
  notificationsIcon: {
    width: 24,
    height: 24,
  },
  notifications: {
    width: "47.31%",
    left: "52.69%",
    right: "0%",
  },
  headericons: {
    top: 60,
    right: 7,
    width: 93,
    height: 44,
    position: "absolute",
  },
  header: {
    height: 103,
    overflow: "hidden",
  },
  marutiGoupIcon: {
    marginLeft: -48,
    top: 60,
    left: "50%",
    width: 100,
    height: 25,
    position: "absolute",
  },
  menuBarIcon: {
    top: 60,
    left: 18,
    width: 28,
    height: 20,
    position: "absolute",
  },
  filtersIcon: {
    height: 25,
    width: 150,
  },
  filters: {
    width: "38.88%",
    top: 130,
    right: "-0.55%",
    left: "61.67%",
    padding: 10,
    flexDirection: "row",
  },
  addTask: {
    backgroundColor: Color.white,
    flex: 1,
    width: "100%",
    height: '100%',
    overflow: "hidden",
  },
});

export default AddTask;