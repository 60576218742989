import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, View, Text, Pressable, Image, TextInput, TouchableOpacity, FlatList, Button } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, Padding, Color, Border, FontSize } from "../GlobalStyles";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { API_URL } from "../Config";
import { Dropdown } from "react-native-element-dropdown";
import axios from "axios";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const UpdateSite = () => {

    const navigation = useNavigation();
    const route = useRoute();
    const { site } = route.params;
    const [updatedSite, setUpdatedSite] = useState({ ...site });
    const [loading, setLoading] = useState(false);

    const [workValue, setWorkValue] = useState(null);
    const [isWorkFocus, setIsWorkFocus] = useState(false);
    const workdata = [
        'Turnkey', 'Rennovatoin', 'Kitchen', 'Furniture',
    ];

    const updateSite = async () => {
       setLoading(true);
        try {
            const response = await axios.put(API_URL + `/client/${site.siteName}`, updatedSite);

            if (response.status === 200) {
                console.log('User updated successfully');
                navigation.navigate('Directory');
                window.alert('Site Updated Successfully!')
                setLoading(false);
            } else {
                console.error('Error updating user');
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const deleteSite = async () => {
        try {
            const response = await axios.delete(API_URL + `/client/${site.siteName}`);

            if (response.status === 204) {
                console.log('Client deleted successfully');
                window.alert('Site Deleted Successfully!')
                navigation.navigate('Directory');
            } else {
                console.error('Error deleting Client');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTaskDetails = () => {
        axios.get(API_URL + `/client/${site.siteName}`)
            .then(response => setUpdatedSite(response.data))
            .catch(error => console.error(error));
    };
    useFocusEffect(
        useCallback(() => {
            fetchTaskDetails();
        }, [site.siteName])
    );

    return (
        <View style={styles.AddSite}>
            <View style={[styles.background]}>
                <LinearGradient
                    style={[styles.background02Bg]}
                    locations={[0.2, 1]}
                    colors={["#173452", "rgba(23, 52, 82, 0)", "#173452"]}
                />
            </View>

            <View style={styles.trash}>
                    <TouchableOpacity style={styles.deleteButton} onPress={deleteSite}>
                        <Image
                            style={styles.trashIcon}
                            contentFit="cover"
                            source={require("../assets/trash.png")}
                        />
                    </TouchableOpacity>
                </View>

            <View
                style={[styles.centercontainer]}
                locations={[0, 0, 1]}
            // colors={["rgba(217, 217, 217, 0)", "#143759"]}
            >


                <Text style={[styles.registerAccount]}>
                    Update Site
                </Text>

                <View style={{ flex: 1, top: '10%' }}>
                    <View style={[styles.namebx]}>
                    <Text style={[styles.name, {color:"#D6D6D6"}]}>Client Name : </Text>
                        <TextInput style={[styles.name]}
                            placeholder="Client Name"
                            value={updatedSite.clientName}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, clientName: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.rolebx]}>
                    <Text style={[styles.role, {color:"#D6D6D6"}]}>Site Name : </Text>
                        <TextInput style={[styles.role]}
                            placeholder="Site Name"
                            value={updatedSite.siteName}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, siteName: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.phonebx]}>
                    <Text style={[styles.phone, {color:"#D6D6D6"}]}>Phone : </Text>
                        <TextInput style={[styles.phone]}
                            placeholder="Phone"
                            value={updatedSite.siteMobile}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, siteMobile: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.emailbx]}>
                    <Text style={[styles.email, {color:"#D6D6D6"}]}>Email : </Text>
                        <TextInput style={[styles.email]}
                            placeholder="Email"
                            value={updatedSite.siteEmail}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, siteEmail: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.managerbx]}>
                    <Text style={[styles.manager, {color:"#D6D6D6"}]}>Manager : </Text>
                        <TextInput style={[styles.manager]}
                            placeholder="Manager"
                            value={updatedSite.manager}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, manager: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.passwordlbx]}>
                    <Text style={[styles.password, {color:"#D6D6D6"}]}>work type : </Text>
                        <Dropdown
                        style={[styles.password, isWorkFocus]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        iconStyle={styles.iconStyle}
                        containerStyle={styles.prioritycontainerstyle}
                        itemTextStyle={styles.itemprioritycontainerstyle}
                        data={workdata.map((label, index) => ({ label, value: (index + 1).toString() }))}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isWorkFocus ? `${updatedSite.work_type}` : '...'}
                        value={workValue}
                        onFocus={() => setIsWorkFocus(true)}
                        onBlur={() => setIsWorkFocus(false)}
                        onChange={item => {
                            setWorkValue(item.value);
                            setUpdatedSite({ ...updatedSite, work_type: item.label })
                            setIsWorkFocus(false);
                        }}
                    />
                    </View>

                    <View style={[styles.confirmpasswordlbx]}>
                    <Text style={[styles.confirmpassword, {color:"#D6D6D6"}]}>Referral Name : </Text>
                        <TextInput style={[styles.confirmpassword]}
                            placeholder="Referral Name"
                            value={updatedSite.reference_name}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, reference_name: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.addressbx]}>
                    <Text style={[styles.address, {color:"#D6D6D6"}]}>Address : </Text>
                        <TextInput style={[styles.address]}
                            placeholder="Address"
                            value={updatedSite.siteAddress}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, siteAddress: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.DOBbx]}>
                    <Text style={[styles.DOB, {color:"#D6D6D6"}]}>Date Of Birth : </Text>
                        <TextInput style={[styles.DOB]}
                            placeholder="Date Of Birth"
                            value={updatedSite.date_of_birth}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, date_of_birth: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.IDbx]}>
                    <Text style={[styles.ID, {color:"#D6D6D6"}]}>Contract No. : </Text>
                        <TextInput style={[styles.ID]}
                            placeholder="Contract No."
                            value={updatedSite.contractNo}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, contractNo: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.IDUploadbx]}>
                    <Text style={[styles.IDUpload, {color:"#D6D6D6"}]}>ID : </Text>
                        <Button style={[styles.IDUpload]}
                            title="⇪  Documents"
                            color={'transparent'}

                        ></Button>
                    </View>


                    <View style={[styles.Bloodbx]}>
                    <Text style={[styles.role, {color:"#D6D6D6"}]}>Registration Date : </Text>
                        <TextInput style={[styles.role]}
                            placeholder="Registration Date"
                            value={updatedSite.registration_date}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, registration_date: text })}
                        ></TextInput>
                    </View>

                    <View style={[styles.interiorbx]}>
                    <Text style={[styles.interior, {color:"#D6D6D6"}]}>Interior Designer : </Text>
                        <TextInput style={[styles.interior]}
                            placeholder="Interior Designer"
                            value={updatedSite.interior_designer}
                            placeholderTextColor={Color.white}
                            onChangeText={text => setUpdatedSite({ ...updatedSite, interior_designer: text })}
                        ></TextInput>
                    </View>
                    
                    {loading ?  <View style={styles.registerbtnDisable} >
                        <Text style={styles.registertxt}>update Site</Text>
                    </View> :  <TouchableOpacity style={styles.registerbtn} onPress={updateSite}>
                        <Text style={styles.registertxt}>update Site</Text>
                    </TouchableOpacity> }
                   


                </View>
            </View>

        </View>

    );
};


const styles = StyleSheet.create({

    registertxt: {
        fontSize: 18,
        fontWeight: "700",
        fontFamily: FontFamily.outfitMedium,
        color: "white",
        textAlign: "center",
        bottom: 10
    },

    trashIcon: {
        width: wp(1),
        height: hp(2.5),
    },

    trash: {
        top: hp(8),
        right: wp(10),
        flexDirection: "row",
        position: "absolute",
    },

    registerbtn: {
        width: "20%",
        height: 20,
        bottom: '56%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },

    registerbtnDisable: {
        opacity:0.5,
        width: "20%",
        height: 20,
        bottom: '56%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },


    dropdown: {
        height: 31,
        width: 160,
        backgroundColor: "#D6D6D6",
        borderRadius: 30,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    selectedTextStyle: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    prioritycontainerstyle: {
        backgroundColor: "#D6D6D6",
        color: 'white',
        borderRadius: 30,
        textAlign: 'center',
        borderWidth: 3,
        width: '1000%',
        alignSelf: 'center'
    },
    itemprioritycontainerstyle: {
        textAlign: 'center',
        color: 'black',
    },

    decorLineIcon: {
        width: '15%',
        height: 24,
        top: '-22%',
        left: '43%',
    },

    registerAccount: {
        top: '-15%',
        width: '80%',
        fontSize: '2vw',
        left: '10%',
        fontWeight: "600",
        fontFamily: FontFamily.outfitMedium,
        textAlign: "center",
        color: Color.white,
        position: 'relative'
    },

    uploadbtnIcon: {
        width: 120,
        height: 120,
        top: '-18%',
        alignSelf: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    confirmpasswordlbx: {
        top: '-47.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    confirmpassword: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    interiorbx: {
        top: '-62.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    interior: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    DOBbx: {
        top: '-52.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    DOB: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    IDbx: {
        top: '-48.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    ID: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    IDUploadbx: {
        top: '-57.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    IDUpload: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        backgroundColor: 'transparent'
    },


    passwordlbx: {
        top: '-38.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    password: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    Referralbx: {
        top: '-72%',
        paddingVertical: 20,
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    Referral: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    emailbx: {
        top: '-34%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        right: "3%",
        width: "45%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    email: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    managerbx: {
        top: '-30%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    manager: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    phonebx: {
        top: '-25%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "3%",
        right: "10%",
        width: "45%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    phone: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    RGDatebx: {
        top: '-81%',
        paddingVertical: 20,
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    RGDate: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    rolebx: {
        top: '-28.5%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        right: "3%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    role: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    Bloodbx: {
        top: '-66.5%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        right: "2%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    Blood: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    namebx: {
        top: '-20%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "3%",
        right: "10%",
        width: "60%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    name: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    addressbx: {
        top: '-43.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "3%",
        right: "10%",
        width: "60%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    address: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    WorkTypebx: {
        top: '-63%',
        paddingVertical: 20,
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    WorkType: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    centercontainer: {
        top: '20%',
        bottom: 10,
        left: '17.5%',
        height: '80%',
        width: '80%',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        backgroundColor: "transparent",
        position: "relative",
    },

    background02Bg: {
        backgroundColor: "transparent",
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    background: {
        position: "absolute",
        overflow: "hidden",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    AddSite: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: 'scroll',
        backgroundColor: "#0C1824",
    },

});


export default UpdateSite;
