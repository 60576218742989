import react, { Component, useState } from "react";
import { View, Text, StyleSheet, Button, Pressable } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Color, FontFamily, FontSize, Border, Padding } from "../GlobalStyles";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation } from "@react-navigation/native";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const Gallery = () => {

    const navigation = useNavigation();

    const handlePhotos = () => {
        console.log('Photos');
    };

    const handleDocuments = () => {
        console.log('Documents');
    };

    return (

        <View style={styles.Gallery}>

            <View style={[styles.background]}>
                <View style={[styles.background01]} />
                <LinearGradient
                    style={[styles.background02]}
                    locations={[0.2, 1]}
                    colors={["#173452", "rgba(23, 52, 82, 0)"]}
                />
            </View>

                <TouchableOpacity style={[styles.PhotosBtn]} onPress={handlePhotos}>
                    <Button style={styles.PhotosBtn} color={'transparent'} ></Button>
                    <Text style={{ color: 'black', fontWeight: '600', fontSize: '1vw', bottom: 8 }}>Photos</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.DocumentsBtn]} onPress={handleDocuments}>
                    <Button style={styles.PhotosBtn} color={'transparent'} ></Button>
                    <Text style={{ color: 'black', fontWeight: '600', fontSize: '1vw', bottom: 8 }}>Documents</Text>
                </TouchableOpacity>

        </View>
    );
}


const styles = StyleSheet.create({
    PhotosBtn: {
        backgroundColor: '#D6D6D6',
        top: hp(4),
        paddingVertical: '1%',
        paddingHorizontal: 20,
        height: hp(3),
        borderWidth: 3,
        borderColor: "#D6D6D6",
        left: wp(24),
        width: wp(30),
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        alignItems: "center",
         position: 'absolute',
        overflow: "hidden",
    },
    DocumentsBtn: {
        backgroundColor: '#D6D6D6',
        top: hp(4),
        paddingVertical: '1%',
        height: hp(3),
        borderWidth: 3,
        borderColor: "#D6D6D6",
        right: wp(15),
        width: wp(30),
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        alignItems: "center",
        position: 'absolute',
        overflow: "hidden",
    },

    Gallery: {
        backgroundColor: Color.white,
        flex: 1,
        width: wp(100),
        height: hp(100),
        overflow: 'scroll',
    },
    background01: {
        backgroundColor: "#0C1824",
        bottom: 0,
        right: 0,
        top: 0,
        left: 0,
        position: "absolute",
    },
    background02: {
        backgroundColor: "transparent",
        bottom: 0,
        right: 0,
        top: 0,
        left: 0,
        position: "absolute",
    },
    background: {
        bottom: 0,
        right: 0,
        top: 0,
        left: 0,
        position: "absolute",
        overflow: "hidden",
        left: 0,
        position: "absolute",
    },
});

export default Gallery;