import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useState, useEffect } from "react";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignUp/SignUp";
import OTPVerfication from "../OTPVerification/OTPVerification";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ChangePassword from "../ChangePassword/ChangePassword";
import Dashboard from "../Dashboard/Dashboard";
import DashboardUpdate from "../Dashboard/DashboardUpdate";
import CustomSideMenu from "./CustomSideMenu";
import MainScreen from "./MainScreen";
import SignUp001 from "../SignUp001/SignUp001";
import SignIn001 from "../SignIn/SignIn001";


const Stack = createStackNavigator();

const Navigation = () => {


    return (
        <NavigationContainer>

            <Stack.Navigator initialRouteName="SignIn" >
                {/* <Stack.Screen name="SignIn" component={SignIn} options={{ headerShown: false }}/> */}

            <Stack.Screen name="MainScreen" component={MainScreen} options={{ headerShown: false }}/>
                {/* <Stack.Screen name="SignUp" component={SignUp} options={{ headerShown: false }} /> */}
                {/* <Stack.Screen name="OTPVerification" component={OTPVerfication} options={{ headerShown: false }} /> */}
                {/* <Stack.Screen name="ForgotPassword" component={ForgotPassword} options={{ headerShown: false }} /> */}
                {/* <Stack.Screen name="ChangePassword" component={ChangePassword} options={{ headerShown: false }} /> */}
                {/* <Stack.Screen name="AddUser" component={SignUp001} options={{headerShown:false}}/> */}
                <Stack.Screen name="SignIn" component={SignIn001} options={{headerShown:false}}/>
            </Stack.Navigator>
        </NavigationContainer>
    );
};

export default Navigation;