import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Pressable, Image, TextInput, TouchableOpacity, FlatList, Button } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontFamily, Padding, Color, Border, FontSize } from "../GlobalStyles";
import { useNavigation } from "@react-navigation/native";
import { API_URL } from "../Config";
import { Dropdown } from "react-native-element-dropdown";



const SignUp001 = () => {

    const navigation = useNavigation();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [manager, setManager] = useState('')
    const [address, setAddress] = useState('');
    const [dob, setDob] = useState('');
    const [identity, setIdentity] = useState('')
    const [workType, setWorkType] = useState('');
    const [referral, setReferral] = useState('');
    const [registrationDate, setRegistrationDate] = useState('');
    const [role, setRole] = useState('');
    const [bloodGropup, setBloodGroup] = useState('');
    const [loading, setLoading] = useState(false);



    const [roleValue, setRoleValue] = useState(null);
    const [isRoleFocus, setIsRoleFocus] = useState(false);
    const roledata = [
        'Admin', 'HR', 'Manager', 'Employee', 'Fitter', 'Operator', 'Helper', 'Mistri', 'Machine Operator', 'Measurement Executive', 'Painter', 'Hardware Store Boy',
        'Production Head', 'Site Supervisor', 'Supplier', 'Material Management', 'Asst. Store Manager', 'Senior Supervisor', 'Office Boy', 'Senior Purchase Executive',
        'Production Manager', 'Driver', 'Quotation & Account Manager', 'Account Head', 'Site Measurement Executive', 'Designer', 'Business Manager', 'CBO- Chief Business Officer',
        'Director', 'CMO - Chief Marketing Officer'
    ];


    const [bloodValue, setBloodValue] = useState(null);
    const [isBloodFocus, setIsBloodFocus] = useState(false);
    const blooddata = [
        'A+', 'O+', 'B+', 'AB+', 'A-', 'O-', 'B-', 'AB-'
    ];

    const date_of_birth = dob;
    const id_proof_number = identity;
    const work_type = workType;
    const reference_name = referral;
    const blood_group = bloodGropup;
    const date_of_join = registrationDate;

    const handleStates = () => {
        setUsername('');
        setEmail('');
        setMobile('');
        setPassword('');
        setConfirmPassword('');
        setManager('');
        setAddress('');
        setDob('');
        setIdentity('');
        setWorkType('');
        setReferral('');
        setRegistrationDate('');
        setRole('');
        setBloodGroup('');
        setLoading(false);
  }
  

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
        handleStates();
    });

    return unsubscribe;
}, [navigation]);

    const handleAddUser = async () => {
        setLoading(true);
        const response = await fetch(API_URL + '/registerUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, mobile, username, role, password, confirmPassword, manager, address, date_of_birth, id_proof_number, work_type, reference_name, date_of_join, blood_group }),
        });

        const data = await response.json();

        if (data.success) {

            navigation.navigate('UserManagement');
            window.alert("User added sucessfully!")
            setLoading(false);

        }
    };

    return (
        <View style={styles.register}>
            <View style={[styles.background]}>
                <LinearGradient
                    style={[styles.background02Bg]}
                    locations={[0.2, 1]}
                    colors={["#173452", "rgba(23, 52, 82, 0)", "#173452"]}
                />
            </View>

            <View
                style={[styles.centercontainer]}
                locations={[0, 0, 1]}
            // colors={["rgba(217, 217, 217, 0)", "#143759"]}
            >

                {/* <View style={[styles.uploadbtnIcon]}>
                    <Image
                        style={styles.uploadbtnIcon}
                        contentFit="cover"
                        source={require("../assets/Upload.png")}
                    />
                </View> */}

                {/* <View style={[styles.content]}> */}
                <Text style={[styles.registerAccount]}>
                    Register User
                </Text>
                {/* <Image
                    style={styles.decorLineIcon}
                    contentFit="cover"
                    source={require("../assets/line.png")}
                /> */}
                {/* </View> */}

                <View style={{ flex: 1, top: '10%' }}>
                    <View style={[styles.namebx]}>
                        <TextInput style={[styles.name]}
                            placeholder="Name"
                            placeholderTextColor={Color.white}
                            value={username}
                            onChangeText={text => setUsername(text)}></TextInput>
                    </View>

                    <View style={[styles.rolebx]}>
                        {/* <TextInput style={[styles.role]}
                        placeholder="Role"
                        placeholderTextColor={Color.white}
                        onChangeText={text => setUsername(text)}></TextInput> */}
                        <Dropdown
                            style={[styles.role, isRoleFocus]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            iconStyle={styles.iconStyle}
                            containerStyle={styles.prioritycontainerstyle}
                            itemTextStyle={styles.itemprioritycontainerstyle}
                            data={roledata.map((label, index) => ({ label, value: (index + 1).toString() }))}
                            maxHeight={300}
                            labelField="label"
                            valueField="value"
                            placeholder={!isRoleFocus ? 'Role' : '...'}
                            value={roleValue}
                            onFocus={() => setIsRoleFocus(true)}
                            onBlur={() => setIsRoleFocus(false)}
                            onChange={item => {
                                setRoleValue(item.value);
                                setRole(item.label);
                                setIsRoleFocus(false);
                            }}
                        />
                    </View>

                    <View style={[styles.phonebx]}>
                        <TextInput style={[styles.phone]}
                            placeholder="Phone"
                            placeholderTextColor={Color.white}
                            value={mobile}
                            onChangeText={text => setMobile(text)}></TextInput>
                    </View>

                    <View style={[styles.emailbx]}>
                        <TextInput style={[styles.email]}
                            placeholder="Email"
                            placeholderTextColor={Color.white}
                            value={email}
                            onChangeText={text => setEmail(text)}></TextInput>
                    </View>

                    <View style={[styles.managerbx]}>
                        <TextInput style={[styles.manager]}
                            placeholder="Manager"
                            placeholderTextColor={Color.white}
                            value={manager}
                            onChangeText={text => setManager(text)}
                        ></TextInput>
                    </View>

                    <View style={[styles.passwordlbx]}>
                        <TextInput style={[styles.password]}
                            placeholder="Password"
                            placeholderTextColor={Color.white}
                            value={password}
                            onChangeText={text => setPassword(text)}></TextInput>
                    </View>

                    <View style={[styles.confirmpasswordlbx]}>
                        <TextInput style={[styles.confirmpassword]}
                            placeholder="Confirm Password"
                            placeholderTextColor={Color.white}
                            value={confirmPassword}
                            onChangeText={text => setConfirmPassword(text)}></TextInput>
                    </View>

                    <View style={[styles.addressbx]}>
                        <TextInput style={[styles.address]}
                            placeholder="Address"
                            placeholderTextColor={Color.white}
                            value={address}
                            onChangeText={text => setAddress(text)}
                        ></TextInput>
                    </View>

                    <View style={[styles.DOBbx]}>
                        <TextInput style={[styles.DOB]}
                            placeholder="Date Of Birth"
                            placeholderTextColor={Color.white}
                            value={dob}
                            onChangeText={text => setDob(text)}
                        ></TextInput>
                    </View>

                    <View style={[styles.IDbx]}>
                        <TextInput style={[styles.ID]}
                            placeholder="Identity Card Details"
                            placeholderTextColor={Color.white}
                            value={identity}
                            onChangeText={text => setIdentity(text)}
                        ></TextInput>
                    </View>

                    <View style={[styles.IDUploadbx]}>
                        <Button style={[styles.IDUpload]}
                            title="⇪  Identity Card Upload"
                            color={'transparent'}
                        // placeholderTextColor={Color.white}
                        ></Button>
                    </View>


                    <View style={[styles.Bloodbx]}>
                        {/* <TextInput style={[styles.role]}
                        placeholder="Role"
                        placeholderTextColor={Color.white}
                        onChangeText={text => setUsername(text)}></TextInput> */}
                        <Dropdown
                            style={[styles.Blood, isBloodFocus]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            iconStyle={styles.iconStyle}
                            containerStyle={styles.prioritycontainerstyle}
                            itemTextStyle={styles.itemprioritycontainerstyle}
                            data={blooddata.map((label, index) => ({ label, value: (index + 1).toString() }))}
                            maxHeight={300}
                            labelField="label"
                            valueField="value"
                            placeholder={!isBloodFocus ? 'Blood' : '...'}
                            value={bloodValue}
                            onFocus={() => setIsBloodFocus(true)}
                            onBlur={() => setIsBloodFocus(false)}
                            onChange={item => {
                                setBloodValue(item.value);
                                setBloodGroup(item.label);
                                setIsBloodFocus(false);
                            }}
                        />
                    </View>

                    <View style={[styles.WorkTypebx]}>
                        <TextInput style={[styles.WorkType]}
                            placeholder="Work Type"
                            placeholderTextColor={Color.white}
                            value={workType}
                            onChangeText={text => setWorkType(text)}
                        ></TextInput>
                    </View>

                    <View style={[styles.Referralbx]}>
                        <TextInput style={[styles.Referral]}
                            placeholder="Referral Name"
                            placeholderTextColor={Color.white}
                            value={referral}
                            onChangeText={text => setReferral(text)}></TextInput>
                    </View>

                    <View style={[styles.RGDatebx]}>
                        <TextInput style={[styles.RGDate]}
                            placeholder="Date of Joining"
                            placeholderTextColor={Color.white}
                            value={registrationDate}
                            onChangeText={text => setRegistrationDate(text)}
                        ></TextInput>
                    </View>

                    <TouchableOpacity style={styles.registerbtn} onPress={handleAddUser}>
                        <Text style={styles.registertxt}>Add User</Text>
                    </TouchableOpacity>


                </View>
            </View>

        </View>

    );
};


const styles = StyleSheet.create({

    registertxt: {
        fontSize: 18,
        fontWeight: "700",
        fontFamily: FontFamily.outfitMedium,
        color: "white",
        textAlign: "center",
        bottom: 10
    },

    registerbtn: {
        width: "20%",
        height: 20,
        bottom: '76%',
        borderRadius: 30,
        backgroundColor: Color.slategray,
        borderColor: "#8E8383",
        borderWidth: 0.6,
        paddingHorizontal: 20,
        paddingVertical: '2%',
        alignSelf: 'center',
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
    },


    dropdown: {
        height: 31,
        width: 160,
        backgroundColor: "#D6D6D6",
        borderRadius: 30,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    selectedTextStyle: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    prioritycontainerstyle: {
        backgroundColor: "#D6D6D6",
        color: 'white',
        borderRadius: 30,
        textAlign: 'center',
        borderWidth: 3,
        width: '1000%',
        alignSelf: 'center'
    },
    itemprioritycontainerstyle: {
        textAlign: 'center',
        color: 'black',
    },

    decorLineIcon: {
        width: 200,
        height: 25,
        top: '-24%',
        left: '43%',
    },

    registerAccount: {
        top: '-15%',
        width: '80%',
        fontSize: '2vw',
        left: '10%',
        fontWeight: "600",
        fontFamily: FontFamily.outfitMedium,
        textAlign: "center",
        color: Color.white,
        position: 'relative'
    },

    uploadbtnIcon: {
        width: 150,
        height: 150,
        top: '-20%',
        alignSelf: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    confirmpasswordlbx: {
        top: '-47.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    confirmpassword: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    DOBbx: {
        top: '-52.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    DOB: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    IDbx: {
        top: '-48.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    ID: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    IDUploadbx: {
        top: '-57%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    IDUpload: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        backgroundColor: 'transparent'
    },


    passwordlbx: {
        top: '-38.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    password: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    Referralbx: {
        top: '-72%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "35%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    Referral: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    emailbx: {
        top: '-33.5%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        right: "3%",
        width: "45%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    email: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        width: '100%'
    },

    managerbx: {
        top: '-30%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    manager: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    phonebx: {
        top: '-25%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "45%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    phone: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    RGDatebx: {
        top: '-81%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "68%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    RGDate: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    rolebx: {
        top: '-28.5%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        right: "3%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    role: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },


    Bloodbx: {
        top: '-66%',
        bottom: '80%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        right: "2%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        alignSelf: 'flex-end',
        position: 'relative',
        overflow: "hidden",
    },
    Blood: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    namebx: {
        top: '-20%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "60%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    name: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        width: "100%",
    },

    addressbx: {
        top: '-43.5%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "60%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    address: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
        width: "100%",
    },


    WorkTypebx: {
        top: '-63%',
        paddingVertical: '2%',
        height: 30,
        borderWidth: 3,
        borderColor: "white",
        // opacity: "50%",
        left: "3%",
        right: "10%",
        width: "30%",
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderRadius: 30,
        alignItems: "center",
        position: 'relative',
        overflow: "hidden",
    },
    WorkType: {
        fontSize: '1vw',
        fontWeight: "600",
        fontFamily: FontFamily.outfitThin,
        color: "white",
        textAlign: "center",
    },

    centercontainer: {
        top: '20%',
        // right: '0%',
        bottom: 10,
        left: '17.5%',
        height: '80%',
        width: '80%',
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        backgroundColor: "transparent",
        position: "relative",
    },

    background02Bg: {
        backgroundColor: "transparent",
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    background: {
        position: "absolute",
        overflow: "hidden",
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
    },

    register: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: 'scroll',
        backgroundColor: "#0C1824",
    },

});


export default SignUp001;