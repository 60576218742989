import * as React from "react";
import { StyleSheet, View, Text,Image } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FontSize, Padding, Color, Border, FontFamily } from "../GlobalStyles";

const ChangePassword = () => {
  return (
    <View style={styles.change}>
      <View style={[styles.background, styles.backgroundPosition]}>
        <View style={[styles.background01, styles.backgroundPosition]} />
        <LinearGradient
          style={[styles.background02, styles.background02Bg]}
          locations={[0.2, 1]}
          colors={["#173452", "rgba(23, 52, 82, 0)"]}
        />
      </View>
      <LinearGradient
        style={[styles.centercontainer, styles.background02Bg]}
        locations={[0, 0, 1]}
        colors={["rgba(217, 217, 217, 0)", "#143759"]}
      />

      <View style={styles.titletxt}>
        <Text style={styles.changePassword}>Change Password</Text>
        <Image
          style={styles.decorLineIcon}
          contentFit="cover"
          source={require("../assets/line.png")}
        />
      </View>

      <View style={[styles.donebtn, styles.donebtnFlexBox]}>
        <Text style={[styles.done, styles.doneTypo]}>Done</Text>
      </View>

      <View style={[styles.passwordbx, styles.confirmbxBorder]}>
        <Text style={[styles.password, styles.doneTypo]}>Password</Text>
      </View>

      <View style={[styles.confirmbx, styles.confirmbxBorder]}>
        <Text style={[styles.password, styles.doneTypo]}>Confirm Password</Text>
      </View>

      <View style={[styles.profile2, styles.donebtnFlexBox]}>
        <Image
          style={styles.profileIcon}
          contentFit="cover"
          source={require("../assets/profile.png")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundPosition: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background02Bg: {
    backgroundColor: "transparent",
    position: "absolute",
  },
  donebtnFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    overflow: "hidden",
  },
  doneTypo: {
    textAlign: "left",
    fontSize: 22,
  },
  confirmbxBorder: {
    paddingVertical: 29,
    paddingHorizontal: 358,
    borderWidth: 3,
    borderColor: Color.gray,
    left: 316,
    right: 314,
    height: "5.86%",
    justifyContent: "center",
    flexDirection: "row",
    borderStyle: "solid",
    borderRadius: 30,
    alignItems: "center",
    position: "absolute",
    overflow: "hidden",
  },
  background01: {
    backgroundColor: "#0C1824",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background02: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  background: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    overflow: "hidden",
  },
  centercontainer: {
    top: 186,
    right: 302,
    bottom: 141,
    left: 302,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
  },
  changePassword: {
    fontSize: 35,
    fontWeight: "700",
    fontFamily: FontFamily.outfitBold,
    textAlign: "center",
    width: 299,
    height: 41,
    color: Color.white,
  },
  decorLineIcon: {
    top:20,
    width: 282,
    height: 35,
  },
  titletxt: {
    marginLeft: -149,
    top: 250,
    alignItems: "center",
    left: "50%",
    position: "absolute",
  },
  done: {
    fontWeight: "500",
    fontFamily: FontFamily.outfitMedium,
    color: Color.white,
  },
  donebtn: {
    width: "23.75%",
    right: "38.06%",
    bottom: 106,
    left: "38.19%",
    backgroundColor: Color.slategray,
    borderColor: Color.gray,
    borderWidth: 0.6,
    alignItems: "flex-end",
    paddingHorizontal: 130,
    paddingVertical: 21,
    borderStyle: "solid",
    borderRadius: 30,
    justifyContent: "center",
    flexDirection: "row",
  },
  password: {
    fontWeight: "100",
    fontFamily: FontFamily.outfitThin,
    color: Color.gray,
  },
  passwordbx: {
    top: "46%",
    bottom: "46.48%",
  },
  confirmbx: {
    top: "54.79%",
    bottom: "39.36%",
  },
  profileIcon: {
    width: 130,
    height: 130,
  },
  profile2: {
    marginLeft: -70,
    top: 111,
    height: 150,
    paddingHorizontal: 8,
    paddingVertical: 4,
    justifyContent: "center",
    flexDirection: "row",
    left: "50%",
  },
  change: {
    backgroundColor: Color.white,
    flex: 1,
    width: "100%",
    height: 1024,
    overflow: "hidden",
  },
});

export default ChangePassword;
