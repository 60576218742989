import React from "react";
import { NavigationContainer, useRoute } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import ChangePassword from "../ChangePassword/ChangePassword";
import Header1 from "../CustomHeader";
import Dashboard from "../Dashboard/Dashboard";
import DashboardUpdate from "../Dashboard/DashboardUpdate";
import CustomSideMenu from "./CustomSideMenu";
import Tasks from "../Tasks/Tasks";
import AddTask from "../Tasks/Addtask";
import UserManagement from "../Usermanagement/UserManagement";
import SignUp001 from "../SignUp001/SignUp001";
import Directory from "../Directory/Directory";
import AddSite from "../AddSite/AddSite";
import CustomSideMenu002 from "./CustomSideMenu002";
import TrackM2 from "../Track/TrackM2";
// import TrackM from "../Track/TrackM";
import CalendarM from "../Calendar/CalendarM";
import UserUpdate from "../Usermanagement/UserUpdate";
import UpdateSite from "../Directory/UpdateSite";
import Gallery from "../Gallery/Gallery";

const Drawer = createDrawerNavigator();

const MainScreen = () => {
    const route = useRoute();
    const { user } = route.params;
    return (
        <Drawer.Navigator
            initialRouteName="Dashboard"
            drawerContent={() => <CustomSideMenu />}
        >

            <Drawer.Screen name="Dashboard" component={Dashboard}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="Dashboard"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />
            <Drawer.Screen name="DashboardUpdate" component={DashboardUpdate}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="DashboardUpdate"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })}
            />

            <Drawer.Screen name="Tasks" component={Tasks}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="Tasks"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="AddTask" component={AddTask}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="AddTask"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />


            <Drawer.Screen name="UserManagement" component={UserManagement}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="UserManagement"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="AddUser" component={SignUp001}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="AddUser"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="Directory" component={Directory}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="Directory"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="AddSite" component={AddSite}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="AddSite"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="CustomeSideMenu002" component={CustomSideMenu002}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="CustomeSideMenu002"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />


            <Drawer.Screen name="CalendarM" component={CalendarM}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="CalendarM"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />
            <Drawer.Screen name="UserUpdate" component={UserUpdate}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="UserUpdate"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="UpdateSite" component={UpdateSite}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="UpdateSite"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="Gallery" component={Gallery}
                initialParams={{ user }}
                options={({ navigation }) => ({
                    header: () => (
                        <Header1
                            title="Gallery"
                            onPress={() => navigation.toggleDrawer()}
                        />
                    ),
                    headerTitle: null
                })} />

            <Drawer.Screen name="TrackM2" component={TrackM2} options={({ navigation }) => ({
                header: () => (
                    <Header1
                        title="TrackM2"
                        onPress={() => navigation.toggleDrawer()}
                    />
                ),
                headerTitle: null
            })} />



        </Drawer.Navigator>
    );
};

export default MainScreen;
