import react, { Component, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Agenda, Calendar, LocaleConfig } from 'react-native-calendars';
import { TouchableOpacity } from "react-native-gesture-handler";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import moment from "moment";
import Dot from "react-native-calendars/src/calendar/day/dot";



const CalendarM = () => {

    const [items, setItems] = useState({});

    const loadItems = (day) => {
        const eventDate = '2023-11-13';
        const eventDetails = eventDate === day.dateString ? [{ name: 'Init Technologyugy' }] : [];
    
        setItems({
          [day.dateString]: eventDetails,
        });
      };

      const events = [
        { title: 'Test Training', date: '2023-11-12T10:00:00' },
        // Add more events as needed
      ];

    return (
        <Agenda style={styles.Calendar}

        items={items}
        loadItemsForMonth={loadItems}
        selected={new Date().getTime()}
        markedDates={{
          // '2023-11-15':{selected:true,marked:true,periods:true}
        }}
        renderItem={(item) => (
            <View style={{ padding: 10 }}>
              <Text>{item.name}</Text>
            </View>
          )}
            // onDayPress={day => {
            //   setSelected(day.dateString);
            // }}
            // markedDates={{
            //   [selected]: {selected: true, disableTouchEvent: true, selectedDotColor: 'orange'}
            // }}
            // onDayPress={day => {
            //     console.log('selected day', day);
            // }}
        />
    );
}

const styles = StyleSheet.create({
    Calendar: {
      // width:wp(80),
      // alignSelf:'center',
      //  left:wp(10),
      // top:hp(30),
      // flex: 1,
      height:hp(100),
       overflow:'scroll'
    },
});

export default CalendarM;